import mapboxgl, { LngLat } from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { Map } from "../Map/component";

interface Props {
    coordinates: LngLat,
    onCoordinates: (coordinates: LngLat) => void,
    color?: string,
    icon?: string,
    iconSize?: number
}

export function MapPointPicker({ coordinates, onCoordinates, icon, iconSize, color }: Props) {
    const map = useRef<mapboxgl.Map | undefined>(undefined);

    const [clicked, setClicked] = useState<LngLat>(new LngLat(0, 0));
    const [styleReload, setStyleReload] = useState(0);

    function setMapData() {
        if (map.current == undefined) return;

        const source = map.current.getSource("findings");
        if (source?.type == "geojson") {
            source.setData({
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: [coordinates.lng, coordinates.lat]
                        },
                        properties: {
                            color: color ?? "#ff0000",
                            icon: icon ?? "cross",
                            iconSize: iconSize ?? 2
                        }
                    }
                ]
            });
        }
    }

    useEffect(() => {
        setMapData();

        if (!map.current) return;

        const bounds = map.current.getBounds();
        if (!bounds.contains(coordinates) && coordinates.lat != 0 && coordinates.lng != 0) {
            map.current?.flyTo({
                center: coordinates,
                zoom: 14
            });
        }
    }, [coordinates]);

    // because calling setMapData() function from "styleload" event doens't work properly (inner React context)
    useEffect(() => {
        setMapData();
    }, [styleReload]);

    function mapInit(mapInstance: mapboxgl.Map) {
        map.current = mapInstance;
        mapInstance.on("click", e => {
            setClicked(e.lngLat);
        });

        mapInstance.on("styleload", () => {
            setStyleReload(Date.now());
        });
    }

    // mapInstance.on event workaround
    useEffect(() => {
        onCoordinates(clicked);
    }, [clicked]);

    return (<div className="map-point-picker">
        <Map init={mapInit} showMapStyles={true} />
        {/* <div>
            <label>Z. délka</label>
            <input
                type="number"
                value={coordinates.lng}
                onChange={(e) => setCoordinates(e.target.valueAsNumber, coordinates.lat)}
            />
        </div>
        <div>
            <label>Z. šířka</label>
            <input
                type="number"
                value={coordinates.lat}
                onChange={(e) => setCoordinates(coordinates.lng, e.target.valueAsNumber)}
            />
        </div> */}
    </div>);
}