import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";

interface FindingFiltersState {
    orderBy: string,
    ascending: boolean,
    findingTypesIds: string[],
    materialsIds: string[],
    ratings: number[]
}

const initialState: FindingFiltersState = {
    orderBy: "",
    ascending: true,
    findingTypesIds: [],
    materialsIds: [],
    ratings: []
}

export const findingFiltersSlice = createSlice({
    name: "findingFilters",
    initialState,
    reducers: {
        resetFindingFilters: () => {
            return initialState;
        },
        setOrderByFindingFilter: (state, action: PayloadAction<{ orderBy: string, ascending: boolean }>) => {
            state.orderBy = action.payload.orderBy;
            state.ascending = action.payload.ascending;
        },
        setFindingTypesFilter: (state, action: PayloadAction<string[]>) => {
            state.findingTypesIds = action.payload;
        },
        setMaterialsFilter: (state, action: PayloadAction<string[]>) => {
            state.materialsIds = action.payload;
        },
        setRatingsFilter: (state, action: PayloadAction<number[]>) => {
            state.ratings = action.payload;
        }
    }
});

export const {
    resetFindingFilters,
    setOrderByFindingFilter,
    setFindingTypesFilter,
    setMaterialsFilter,
    setRatingsFilter
} = findingFiltersSlice.actions;

export const selectFindingFilters = (state: RootState) => {
    return state.findingFilters
}

export const selectFindingFiltersActive = (state: RootState) => {
    const filters = state.findingFilters;
    return filters.orderBy.length > 0 || filters.materialsIds.length > 0 || filters.findingTypesIds.length > 0 || filters.ratings.length > 0;
}

export default findingFiltersSlice.reducer;