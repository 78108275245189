import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editCurrentUser } from "../../services/Api/users";
import { selectCurrentUser, setCurrentUser } from "../../state/features/user";

export function UserSettings() {
    const dispatch = useDispatch();

    const { register, handleSubmit } = useForm({ mode: 'onBlur' });

    const currentUser = useSelector(selectCurrentUser);

    async function onSubmit(data: any) {
        const result = await editCurrentUser(data.fullName);

        if (result) {
            dispatch(setCurrentUser(result));
            toast("Uživatelské nastavení uloženo", { type: "success" });
        } else {
            toast("Při ukládání uživatelského nastavení došlo k chybě", { type: "error" });
        }
    }

    return <form onSubmit={handleSubmit(onSubmit)} className="user-settings">
        <div className="form-control">
            <label>Jméno</label>
            <input type="text" {...register("fullName", { value: currentUser?.fullName ?? "" })} />
        </div>
        <button className="primary">Uložit jméno</button>
    </form>;
}