import { useState } from "react";

interface Props {
    colors: string[],
    value: string,
    onChange: (color: string) => void
}

export const DEFAULT_COLORS = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB']

export function ColorPicker({ colors, onChange, value }: Props) {
    return (<ul className="color-picker">
        {
            colors.map(color =>
                <li
                    style={{ background: color }}
                    onClick={() => onChange(color)}
                    className={value == color ? "selected" : ""}>
                </li>
            )
        }
    </ul>);
}