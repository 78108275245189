import { getLocalities } from "../../services/Api/localities";
import { setLocalities } from "../../state/features/locality";
import { useAppDispatch } from "../../state/hooks";

export function useLoadLocalities() {
    const dispatch = useAppDispatch();

    async function loadLocalities() {
        const localities = await getLocalities();
        dispatch(setLocalities(localities));
    }

    return loadLocalities;
}
