import { Modal } from "../Modal"

interface Props {
    show: boolean,
    setShow: (state: boolean) => void,
    alertText: string,
    onDelete: () => void
}

export function DeleteModal({ show, setShow, alertText, onDelete }: Props) {
    function handleDelete() {
        onDelete();
        setShow(false);
    }

    return (<Modal show={show} setShow={setShow}>
        <div className="delete-modal">
            <h2>{alertText}</h2>
            <button className="primary" onClick={handleDelete}>Odstranit</button>
            <button onClick={() => setShow(false)}>Zrušit</button>
        </div>
    </Modal>);
}