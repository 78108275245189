import { LngLat } from "mapbox-gl";
import { CreateFindingEntryModel } from "../../models/partial-contracts/create-finding-entry.model";
import { selectFindingTypes } from "../../state/features/findingType";
import { selectMaterials } from "../../state/features/material";
import { useAppSelector } from "../../state/hooks";
import { ItemsSelect } from "../ItemsSelect";
import { MapPointPicker } from "../MapPointPicker";
import { intToColor } from "../../helpers/color.helper";
import { deleteFindingImage, uploadFindingImage } from "../../services/Api";
import { ImageModel } from "../../models/image.model";
import { ImagesEditor } from "../ImagesEditor";
import { getLandInfo } from "../../services/Api/nominatim";
import { FindingRatingInput } from "../FindingRatingInput";
import { selectLocalities } from "../../state/features/locality";

interface Props {
    entry: CreateFindingEntryModel,
    entryChange: (entry: CreateFindingEntryModel) => void,
    onDate?: (date: Date) => void
}

// TODO: needs refactor
export function CreateFindingsEntry({ entry, entryChange, onDate }: Props) {
    const materials = useAppSelector(selectMaterials);
    const findingTypes = useAppSelector(selectFindingTypes);
    const localities = useAppSelector(selectLocalities);

    function handleEntryChange(field: string, value: any) {
        entryChange({
            ...entry,
            [field]: value
        });
    }

    async function handleDeleteImage(image: ImageModel) {
        entryChange({
            ...entry,
            images: entry.images.filter(i => i.id != image.id)
        });
        await deleteFindingImage(image.id);
    }

    async function handleCoordinates(longitude: number, latitude: number, altitude: number) {
        const landInfo = await getLandInfo(longitude, latitude);

        entryChange({
            ...entry,
            latitude,
            longitude,
            altitude,
            landRegistry: landInfo.landRegistry,
            county: landInfo.county
        });
    }

    return (<div className="create-findings-entry">
        <div className="create-findings-entry-images">
            <label>Fotky ({entry.images.length})</label>
            <ImagesEditor
                images={entry.images}
                setImages={images => entryChange({ ...entry, images })}
                uploadFunction={uploadFindingImage}
                deleteImage={handleDeleteImage}
                onCoordinates={handleCoordinates}
                onDate={onDate}
            />
        </div>

        <MapPointPicker
            coordinates={new LngLat(entry.longitude, entry.latitude)}
            onCoordinates={(coordinates) => entryChange({
                ...entry,
                latitude: coordinates.lat,
                longitude: coordinates.lng
            })}
            color={entry.material == undefined ? undefined : intToColor(entry.material?.color)}
            icon={entry.findingType?.icon}
            iconSize={entry.findingType?.iconSize}
        />

        <div className="create-findings-entry-gps">
            <div className="form-control">
                <label>GPS</label>
                <input
                    value={`${entry.longitude.toFixed(7)} ${entry.latitude.toFixed(7)}`}
                    onChange={e => {
                        try {
                            const split = e.target.value.split(" ");
                            if (split.length != 2) throw new Error("invalid GPS coordinates");

                            const coordinates = split.map(parseFloat);

                            // TODO: sanitize coordinates range validity

                            entryChange({
                                ...entry,
                                longitude: coordinates[0],
                                latitude: coordinates[1]
                            });
                        } catch (e) {
                            console.log("COORDS PARSE ERROR", e);
                        }
                    }}
                />
            </div>

            <div className="form-control">
                <label>Nadmořská výška (m)</label>
                <input
                    value={entry.altitude.toFixed(1)}
                    step={0.1}
                    type={"number"}
                    onChange={e => handleEntryChange('altitude', e.target.valueAsNumber)}
                />
            </div>

            <div className="form-control">
                <label>Okres</label>
                <input
                    value={entry.county}
                    onChange={e => handleEntryChange('county', e.target.value)}
                />
            </div>

            <div className="form-control">
                <label>Katastr</label>
                <input
                    value={entry.landRegistry}
                    onChange={e => handleEntryChange('landRegistry', e.target.value)}
                />
            </div>
        </div>

        <div className="create-findings-entry-form">
            <div className="form-control">
                <label>* Předmět</label>
                <ItemsSelect
                    value={entry.findingType}
                    items={findingTypes}
                    nameExtractor={item => item.identifier}
                    onChange={(item) => handleEntryChange('findingType', item)}
                />
            </div>

            <div className="form-control">
                <label>* Materiál</label>
                <ItemsSelect
                    value={entry.material}
                    items={materials}
                    nameExtractor={item => item.identifier}
                    onChange={item => handleEntryChange('material', item)}
                />
            </div>

            <div className="form-control">
                <label>* Lokalita</label>
                <ItemsSelect
                    value={entry.locality}
                    items={localities}
                    nameExtractor={item => item.identifier}
                    onChange={item => handleEntryChange('locality', item)}
                />
            </div>

            <div className="form-control">
                <label>Hmotnost (g)</label>
                <input
                    value={entry.weight ?? ''}
                    type={"number"}
                    min={0}
                    max={50000}
                    step={0.1}
                    onChange={e => handleEntryChange('weight', e.target.valueAsNumber)}
                />
            </div>

            <div className="form-control">
                <label>Hodnocení</label>
                <FindingRatingInput
                    value={entry.rating}
                    onChange={value => handleEntryChange('rating', value)}
                />
            </div>

            <div className="form-control">
                <label>Hloubka (cm)</label>
                <input
                    value={entry.depth?.toFixed(0) ?? ''}
                    type={"number"}
                    min={0}
                    max={10000}
                    step={1}
                    onChange={e => handleEntryChange('depth', e.target.valueAsNumber)}
                />
            </div>

            <div className="textarea form-control">
                <label>Poznámka</label>
                <textarea
                    value={entry.description}
                    onChange={e => handleEntryChange('description', e.target.value)}
                />
            </div>
        </div>
    </div>)
}