import { API_URL } from "../../config";
import { LocalityModel } from "../../models/locality.model";
import { buildAuthorizationHeaders, buildJSONContentTypeHeaders } from "./headers";

import axios from "axios";

export async function getLocalities(): Promise<LocalityModel[]> {
    const res = await axios(`${API_URL}/api/localities`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return res.data?.localities as LocalityModel[] ?? [];
}

export async function createLocality(identifier: string): Promise<LocalityModel> {
    const res = await axios(`${API_URL}/api/localities`, {
        data: {
            identifier
        },
        method: "POST",
        headers: {
            ...buildAuthorizationHeaders(),
            ...buildJSONContentTypeHeaders()
        }
    });

    return res.data?.locality as LocalityModel ?? undefined;
}

export async function deleteLocality(id: string): Promise<void> {
    await axios(`${API_URL}/api/localities/${id}`, {
        method: "DELETE",
        headers: {
            ...buildAuthorizationHeaders(),
        }
    });
}