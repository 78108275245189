import axios from "axios";
import { API_URL } from "../../config";
import { UserDefaultSettings } from "../../models/users/user-default-settings.model";
import { UserInfo } from "../../models/users/user-info.model";
import { buildAuthorizationHeaders, buildJSONContentTypeHeaders } from "./headers";

export async function getCurrentUser() {
    const response = await axios(`${API_URL}/api/users/current`, {
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return response.data as UserInfo;
}

export async function editCurrentUser(fullName: string) {
    const response = await axios(`${API_URL}/api/users/current`, {
        method: "PUT",
        data: {
            fullName
        },
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return response.data as UserInfo;
}

export async function getUserDefaultSettings(): Promise<UserDefaultSettings> {
    const response = await axios(`${API_URL}/api/users/default_settings`, {
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return response.data?.defaultSettings as UserDefaultSettings;
}

export async function editUserDefaultSettings(defaultSettings: UserDefaultSettings): Promise<UserDefaultSettings> {
    const response = await axios(`${API_URL}/api/users/default_settings`, {
        method: "PUT",
        data: {
            zoom: defaultSettings.zoom,
            latitude: defaultSettings.latitude,
            longitude: defaultSettings.longitude
        },
        headers: {
            ...buildAuthorizationHeaders(),
            ...buildJSONContentTypeHeaders()
        }
    });

    return response.data?.defaultSettings as UserDefaultSettings;
}

export async function seedUser() {
    await axios(`${API_URL}/api/users/seed`, {
        method: "POST",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });
}