import { useEffect, useRef } from "react";

export const useClickOutside = (callback: () => any) => {
    const ref = useRef<HTMLElement>();

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as any)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);

    return ref;
};