import mapboxgl, { LngLat } from "mapbox-gl";

import { useEffect, useRef } from "react";
import { AreasFillLayer, DefaultStyle, FindingsHeatmapLayer } from "../../consts";
import { MapLayerToggle } from "../MapLayerToggle";
import { MapStyleManager } from "../MapStyleManager";

import HeatmapIcon from "../../assets/icons/heatmap.svg";
import AreasIcon from "../../assets/icons/areas.svg";

import "./style.scss";
import { MapFindingFilters } from "../MapFindingFilters";
import { UserDefaultLocation } from "../../models/users/user-default-location.model";

interface Props {
    showMapStyles?: boolean,
    showHeatmapToggle?: boolean,
    showAreasToggle?: boolean,
    showFindingFilters?: boolean,
    defaultLocation?: UserDefaultLocation,
    loading?: boolean
    init: (map: mapboxgl.Map) => void
}

export function Map({ init, showMapStyles, showHeatmapToggle, showAreasToggle, showFindingFilters, defaultLocation, loading }: Props) {
    const mapContainer = useRef(null);
    const map = useRef<mapboxgl.Map | undefined>(undefined);

    useEffect(() => {
        if (map.current != undefined) return;

        if (defaultLocation != undefined) {
            map.current = new mapboxgl.Map({
                container: mapContainer.current as any,
                style: DefaultStyle,
                center: new LngLat(defaultLocation.longitude, defaultLocation.latitude),
                zoom: defaultLocation.zoom,

                preserveDrawingBuffer: true
            });
        } else {
            map.current = new mapboxgl.Map({
                container: mapContainer.current as any,
                style: DefaultStyle,

                preserveDrawingBuffer: true
            });
        }

        // fire initial styleload event
        map.current.once("load", () => {
            map.current?.fire("styleload");
        });

        // make findings_heatmap layer and areas_fill layer invisible everytime style reloads
        map.current.on("styleload", () => {
            map.current?.setLayoutProperty(FindingsHeatmapLayer, "visibility", "none");
            map.current?.setLayoutProperty(AreasFillLayer, "visibility", "none");
        });

        // load images
        // TODO: move bulk load to helpers
        function loadMapIcon(icon: string) {
            map.current?.loadImage(`/map-icons/${icon}.png`, (error, image) => {
                if (error) throw error;

                map.current?.addImage(icon, image as any, { sdf: true });
            });
        }

        ["circle", "square", "diamond", "cross", "triangle"].forEach(loadMapIcon);

        init(map.current);
    }, []);

    return (<div ref={mapContainer} className="map-container map">
        <div className={loading ? "map-loading active" : "map-loading"}></div>
        <div className="map-controls">
            {
                showMapStyles &&
                <MapStyleManager map={map} />
            }
            {
                showHeatmapToggle &&
                <MapLayerToggle map={map.current} layers={["findings_heatmap"]} defaultActive={false} icon={HeatmapIcon} />
            }
            {
                showAreasToggle &&
                <MapLayerToggle map={map.current} layers={["areas", "areas_line"]} defaultActive={true} icon={AreasIcon} />
            }
        </div>
        <div className="map-filters">
            {
                showFindingFilters &&
                <MapFindingFilters map={map.current} />
            }
        </div>
    </div>);
}