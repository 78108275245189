import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserDefaultSettings } from "../../models/users/user-default-settings.model";
import { UserInfo } from "../../models/users/user-info.model";
import { RootState } from "../store";

interface UserState {
    currentUser: UserInfo | undefined,
    defaultSettings: UserDefaultSettings | undefined
}

const initialState: UserState = {
    currentUser: undefined,
    defaultSettings: undefined
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<UserInfo>) => {
            state.currentUser = action.payload;
        },
        resetCurrentUser: (state) => {
            state.currentUser = undefined;
        },

        setUserDefaultSettings: (state, action: PayloadAction<UserDefaultSettings>) => {
            state.defaultSettings = action.payload;
        },
        resetUserDefaultSettings: (state) => {
            state.defaultSettings = undefined;
        }
    }
});

export const { setCurrentUser, resetCurrentUser, setUserDefaultSettings, resetUserDefaultSettings } = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectUserDefaultSettings = (state: RootState) => state.user.defaultSettings;

export default userSlice.reducer;