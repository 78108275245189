import { useClickOutside } from "../../hooks/click-oustide.hook";
import { FindingRating } from "../FindingRating";

interface Props {
    selectedRatings: number[],
    setSelectedRatings: (ratings: number[]) => void,
    onClickOutside: () => void
}

export function FindingsRatingsFilter({ selectedRatings, setSelectedRatings, onClickOutside }: Props) {
    const clickOutsideRef = useClickOutside(onClickOutside);

    const ratings = [0, 1, 2, 3];

    function handleSelect(rating: number) {
        const index = selectedRatings.indexOf(rating);
        let newSelected = [] as number[];
        if (index >= 0) {
            newSelected = [...selectedRatings.filter(r => rating != r)];
        } else {
            newSelected = [...selectedRatings, rating];
        }

        setSelectedRatings(newSelected);
    }

    return (<ul className="findings-items-filter" ref={clickOutsideRef as any}>
        {
            ratings.map(rating =>
                <li key={rating} onClick={() => handleSelect(rating)}>
                    <div className={selectedRatings.includes(rating) ? "filter-checkbox checked" : "filter-checkbox"}></div>
                    <span>
                        <FindingRating rating={rating} />
                    </span>
                </li>)
        }
    </ul>);
}