import { LargePrintTagModel, LargePrintTagSettingsModel } from "../../models/print/large-print-tag.model";
import { LargePrintTag } from "../LargePrintTag/component";
import { Modal } from "../Modal"

interface Props {
    show: boolean,
    setShow: (state: boolean) => void,
    printData: LargePrintTagModel,
    setPrintSettings: (settings: LargePrintTagSettingsModel) => void,
    handlePrint: () => void
}

export function LargePrintTagPreviewModal({ show, setShow, printData, setPrintSettings, handlePrint }: Props) {
    function handleSetImageTransform(index: number, x: number, y: number, scale: number) {
        const images = printData.settings.images;

        images[index].x = x;
        images[index].y = y;
        images[index].scale = scale;

        setPrintSettings({ ...printData.settings, images });
    }

    function handleSetMapPosition(lat: number, lon: number, zoom: number) {
        setPrintSettings({
            ...printData.settings, mapPosition: {
                latitude: lat,
                longitude: lon,
                zoom: zoom
            }
        });
    }

    function setDoublePrintSettings() {
        setPrintSettings({
            ...printData.settings,
            imageVariant: "double",
            images: [
                {
                    scale: 1, x: 0, y: 0
                },
                {
                    scale: 1, x: 0, y: 0
                }
            ]
        });
    }

    function setTriplePrintSettings() {
        setPrintSettings({
            ...printData.settings,
            imageVariant: "triple",
            images: [
                {
                    scale: 1, x: 0, y: 0
                },
                {
                    scale: 1, x: 0, y: 0
                },
                {
                    scale: 1, x: 0, y: 0
                }
            ]
        });
    }

    return (<Modal show={show} setShow={setShow}>
        <div style={{ background: 'white' }} className="large-print-tag-preview-modal">
            <div className="large-print-tag-preview-modal-aside">
                <h3>Náhled tisku</h3>
                <span>{printData.settings.imageVariant}</span>

                <div className="images">
                    <span>Rozvržení obrázků</span>
                    <div>
                        <button onClick={() => setDoublePrintSettings()} className={`${printData.settings.imageVariant == "double" ? "primary" : ""}`}>Dvojité</button>
                        <button onClick={() => setTriplePrintSettings()} className={`${printData.settings.imageVariant == "triple" ? "primary" : ""}`}>Trojité</button>
                    </div>
                </div>
                <div className="submit">
                    <button className="secondary" onClick={() => setShow(false)}>Zrušit</button>
                    <button className="primary" onClick={handlePrint}>Tisknout</button>
                </div>
            </div>
            <div className="large-print-tag-preview-modal-preview">
                <LargePrintTag
                    print={false}
                    printData={printData}
                    area={-1}
                    setImageTransform={handleSetImageTransform}
                    setMapPosition={handleSetMapPosition}
                />
            </div>
        </div>
    </Modal>);
}