import { MutableRefObject } from "react";
import { Map } from "../Map/component";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { UserDefaultLocation } from "../../models/users/user-default-location.model";

interface Props {
    initialGeojson?: any,
    dataExtractor: MutableRefObject<() => any>,
    defaultLocation?: UserDefaultLocation
}

export function MapNewArea({ dataExtractor, initialGeojson, defaultLocation }: Props) {

    function mapInit(map: mapboxgl.Map) {
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
                trash: true
            }
        });

        map.on('draw.modechange', (_) => {
            const data = draw.getAll();
            if (draw.getMode() == 'draw_polygon') {
                draw.delete(data.features.map(f => f?.id as string ?? "").slice(0, -1));
            }
        });

        map.addControl(draw);

        // populate with initial area geojson
        if (initialGeojson) {
            draw.add(initialGeojson);
        }

        dataExtractor.current = () => draw.getAll();
    }

    return (<Map
        init={mapInit}
        showMapStyles={true}
        defaultLocation={defaultLocation}
    />);
}