import React, { useEffect, useState } from "react";
import Compressor from "compressorjs";

import exif from "exifr";
import heic2any from "heic2any";

import { ImageModel } from "../../models/image.model";
import { ImageMetaModel } from "../../models/image-meta.model";
import { toast } from "react-toastify";
import { ImageWithMetaModel } from "../../models/image-with-meta.model";

interface Props {
    uploadFunction?: (image: File | Blob) => Promise<ImageModel>
    onUpload?: (image: ImageWithMetaModel) => void,
    ignoreMeta?: boolean
}

export default function ImageInput({ uploadFunction, onUpload, ignoreMeta }: Props) {

    // because of correct hook update
    const [lastImage, setLastImage] = useState<ImageWithMetaModel>();

    const [uploading, setUploading] = useState(false);

    async function handleFileChange(e: React.ChangeEvent) {
        setUploading(true);

        try {
            const target = e.target as HTMLInputElement;
            if (target == undefined) return;

            if (!target.files || target.files.length < 0) return;

            for (let i = 0; i < target.files.length; i++) {
                const file = target.files[i];

                console.log("COMPRESSING", target.files.length);

                let blob = new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

                let meta: ImageMetaModel | undefined = undefined;
                if (ignoreMeta === undefined || ignoreMeta === false) {
                    meta = await tryExtractMeta(file);
                }

                if (file.type === "image/heic") {
                    blob = await heic2any({ blob }) as Blob;
                }

                if (uploadFunction) {
                    await new Promise<void>((resolve) => {
                        new Compressor(blob, {
                            quality: 0.5,
                            success: async (result) => {
                                try {
                                    const uploadImage = await uploadFunction(result);

                                    setLastImage({ ...uploadImage, meta });
                                    resolve();
                                } catch (err) {
                                    console.log("Upload error", err);

                                    toast.error("Nastala chyba během nahrávání obrázku.");
                                    resolve();
                                }
                            },
                            error(err) {
                                console.log("Compression error", err);

                                toast.error("Nastala chyba během konverze obrázku.");
                                resolve();
                            }
                        });
                    });
                }
            }

            target.value = "";
            setUploading(false);

        } catch (err) {
            console.log("Convert error", err);

            toast.error("Nastala chyba během konverze obrázku (pravděpodobně chybný formát).");
            setUploading(false);
        }
    }

    useEffect(() => {
        if (!lastImage) return;

        if (onUpload) {
            onUpload(lastImage);
        }
    }, [lastImage]);

    async function tryExtractMeta(file: File): Promise<ImageMetaModel | undefined> {
        const exifData = await exif.parse(file as any);

        console.log("EXIF DATA", exifData);

        if (exifData?.longitude && exifData?.latitude && exifData?.GPSAltitude) {
            return {
                longitude: exifData.longitude,
                latitude: exifData.latitude,
                altitude: exifData.GPSAltitude,
                date: exifData?.CreateDate ?? exifData?.DateTimeOriginal
            }
        } else {
            toast.error("Nelze extrahovat EXIF data z obrázku.");
        }
    }

    return (<input onChange={handleFileChange} type="file" name="image" disabled={uploading} multiple={true} />);
}