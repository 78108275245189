import mapboxgl from "mapbox-gl"
import { MutableRefObject, useState } from "react"
import { Aerial03, Aerial06, Aerial12, Aerial15, Aerial18, Aerial, BasicStyle, DefaultStyle, GeographicStyle, NineteenthCentury, TopoStyle } from "../../consts";

import SettingsIcon from "../../assets/icons/settings.svg";

interface Props {
    map: MutableRefObject<mapboxgl.Map | undefined>
}

export function MapStyleManager({ map }: Props) {
    const [showStyles, setShowStyles] = useState(false);
    const [selectedStyle, setSelectedStyle] = useState(DefaultStyle);

    const styles = [
        { style: TopoStyle, name: "Topografická" },
        { style: NineteenthCentury, name: "19. století" },
        { style: BasicStyle, name: "Základní" },
        { style: GeographicStyle, name: "Zeměpisná" },
        { style: Aerial03, name: "Letecká '03" },
        { style: Aerial06, name: "Letecká '06" },
        { style: Aerial12, name: "Letecká '12" },
        { style: Aerial15, name: "Letecká '15" },
        { style: Aerial18, name: "Letecká '18" },
        { style: Aerial, name: "Letecká" }
    ];

    function setMapStyle(style: string) {
        setShowStyles(false);

        // custom styleload event
        map.current?.once("styledata", () => {
            map.current?.fire("styleload");
        });

        map.current?.setStyle(style);
        setSelectedStyle(style);
    }

    return <div className="map-style-manager">
        <button
            type="button"
            onClick={() => setShowStyles(!showStyles)}
            className={`icon ${showStyles ? "active" : ""}`}
        >
            <img src={SettingsIcon} />
        </button>
        {
            showStyles &&
            <div className="map-style-manager-styles">
                {
                    styles.map(s => 
                    <div key={s.name} className="map-style-manager-styles-entry">
                        <button
                            type="button"
                            className={selectedStyle == s.style ? "primary" : ""}
                            onClick={() => setMapStyle(s.style)}
                        >
                            {s.name}
                        </button>
                    </div>)
                }
            </div>
        }
    </div>
}