import { AreaModel } from "../../models/area.model";

import AreaPlaceholderImage from "../../assets/images/no-image.png";
import { sanitizeAreaName } from "../../helpers/area.helper";

interface Props {
    area: AreaModel,
    onClick: () => void,
    onPreview: () => void,
    onPreviewLeave: () => void
}

export function AreaCard({ area, onClick, onPreview, onPreviewLeave }: Props) {
    return (<li
        className="area-card"
        onClick={onClick}
        onMouseEnter={onPreview}
        onMouseLeave={onPreviewLeave}
    >
        <img src={area.images.length > 0 ? area.images[0]?.url : AreaPlaceholderImage} />
        <span className="area-card-name">{sanitizeAreaName(area.name)}</span>
        <span className="area-card-findings">({area.findingsCount} nálezů)</span>
    </li>);
}