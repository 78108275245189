import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ImagesEditor } from "../../components/ImagesEditor";
import { MapNewArea } from "../../components/MapNewArea";
import { ImageModel } from "../../models/image.model";
import { createArea, uploadAreaImage } from "../../services/Api";
import { CreateAreaValidator } from "../../validators/create-area.validator";

import { ImageWithMetaModel } from "../../models/image-with-meta.model";
import { useAppSelector } from "../../state/hooks";
import { selectUserDefaultSettings } from "../../state/features/user";

export function CreateArea() {
    const navigate = useNavigate();
    const defaultSettings = useAppSelector(selectUserDefaultSettings);

    const { handleSubmit, register, formState: { errors } } = useForm({ mode: "onBlur" });

    const dataExtractor = useRef<() => any>(() => { });

    const [images, setImages] = useState<ImageWithMetaModel[]>([]);

    async function submit(formData: any) {
        const data = dataExtractor.current();
        const coordinates = data.features[0]?.geometry?.coordinates;

        // if (!coordinates) {
        //     toast("Nelze vytvořit oblast - prosím zaneste oblast do mapy", { type: "error" });
        //     return;
        // }

        await createArea(
            formData.name,
            formData.description,
            coordinates ?? [],
            images.map(image => image.id)
        );

        navigate('/areas');
    }

    return (<div className="page-create-area">
        <section className="page-create-area-sidebar">
            <h2>Vytvořit novou oblast</h2>
            <form onSubmit={handleSubmit(submit)}>
                <div className="form-control">
                    <label>* Název</label>
                    <input type="text" {...register("name", CreateAreaValidator.name)} />
                    <span className="form-control-errors">{errors?.name?.message}</span>
                </div>

                <div className="form-control">
                    <label>Popis</label>
                    <textarea {...register("description", CreateAreaValidator.description)}></textarea>
                    <span className="form-control-errors">{errors?.description?.message}</span>
                </div>

                <div className="form-control">
                    <label>Fotky</label>
                    <ImagesEditor
                        ignoreMeta={true}
                        images={images}
                        setImages={setImages}
                        uploadFunction={uploadAreaImage}
                        deleteImage={(image) => {
                            setImages(images.filter((i: ImageModel) => i.id != image.id))
                        }}
                    />
                </div>

                <button type="submit">Uložit</button>
            </form>
        </section>
        <section className="page-create-area-map">
            <MapNewArea
                dataExtractor={dataExtractor}
                defaultLocation={defaultSettings === undefined ? undefined : { zoom: defaultSettings.zoom, latitude: defaultSettings.latitude, longitude: defaultSettings.longitude }}
            />
        </section>
    </div>);
}