import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MaterialModel } from "../../models/material.model";
import { RootState } from "../store";

interface MaterialState {
    materials: MaterialModel[]
}

const initialState: MaterialState = {
    materials: []
}

export const materialSlice = createSlice({
    name: 'material',
    initialState,
    reducers: {
        setMaterials: (state, action: PayloadAction<MaterialModel[]>) => {
            state.materials = action.payload;
        }
    }
});

export const { setMaterials } = materialSlice.actions;

export const selectMaterials = (state: RootState) => state.material.materials;

export default materialSlice.reducer;