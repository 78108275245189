import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalityModel } from "../../models/locality.model";
import { RootState } from "../store";

interface LocalityState {
    localities: LocalityModel[]
}

const initialState: LocalityState = {
    localities: []
}

export const localitySlice = createSlice({
    name: 'locality',
    initialState,
    reducers: {
        setLocalities: (state, action: PayloadAction<LocalityModel[]>) => {
            state.localities = action.payload;
        }
    }
});

export const { setLocalities } = localitySlice.actions;

export const selectLocalities = (state: RootState) => state.locality.localities;

export default localitySlice.reducer;