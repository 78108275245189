import { getCurrentUser, getUserDefaultSettings } from "../../services/Api/users";
import { resetCurrentUser, resetUserDefaultSettings, setCurrentUser, setUserDefaultSettings } from "../../state/features/user";
import { useAppDispatch } from "../../state/hooks";

export function useLoadCurrentUser() {
    const dispatch = useAppDispatch();

    async function loadCurrentUser() {
        const userInfo = await getCurrentUser();
        const defaultSettings = await getUserDefaultSettings();

        dispatch(setCurrentUser(userInfo));
        dispatch(setUserDefaultSettings(defaultSettings));
    }

    function unloadCurrentUser() {
        dispatch(resetCurrentUser());
        dispatch(resetUserDefaultSettings());
    }

    return { loadCurrentUser, unloadCurrentUser };
}