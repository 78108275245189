import axios from "axios";
import { URLSearchParams } from "url";
import { API_URL } from "../../config";
import { CreateFindingModel } from "../../models/contracts/create-finding.model";
import { EditFindingModel } from "../../models/contracts/edit-finding.model";
import { FindingModel } from "../../models/finding.model";
import { buildAuthorizationHeaders, buildJSONContentTypeHeaders } from "./headers";

export async function createFinding(areaId: string, createFinding: CreateFindingModel): Promise<string> {
    const res = await axios(`${API_URL}/api/areas/${areaId}/finding`, {
        data: createFinding,
        method: "POST",
        headers: {
            ...buildAuthorizationHeaders(),
            ...buildJSONContentTypeHeaders()
        }
    });

    return res.data?.id as string ?? undefined;
}

export async function createFindings(areaId: string, createFindings: CreateFindingModel[]): Promise<string[]> {
    const res = await axios(`${API_URL}/api/areas/${areaId}/findings`, {
        data: {
            findings: createFindings
        },
        method: "POST",
        headers: {
            ...buildAuthorizationHeaders(),
            ...buildJSONContentTypeHeaders()
        }
    });

    return res.data?.ids as string[] ?? undefined;
}

export async function editFinding(id: string, editFinding: EditFindingModel): Promise<FindingModel> {
    const res = await axios(`${API_URL}/api/findings/${id}`, {
        method: "PUT",
        data: {
            ...editFinding
        },
        headers: {
            ...buildAuthorizationHeaders(),
        }
    });

    return res.data?.finding as FindingModel;
}

export async function deleteFinding(id: string): Promise<void> {
    await axios(`${API_URL}/api/findings/${id}`, {
        method: "DELETE",
        headers: {
            ...buildAuthorizationHeaders(),
        }
    });
}

export async function getFinding(id: string): Promise<FindingModel> {
    const response = await axios(`${API_URL}/api/findings/${id}`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders(),
        }
    });

    return response?.data?.finding as FindingModel;
}

export async function getFindings(areaId: string, orderBy: string, ascending: boolean, findingTypesIds: string[], materialsIds: string[], ratings: number[]): Promise<FindingModel[]> {
    const params = [];
    if (orderBy?.length > 0) {
        params.push(`orderBy=${orderBy}&ascending=${ascending ? "true" : "false"}`);
    }

    if (findingTypesIds.length > 0) {
        params.push(`findingTypesIds=${findingTypesIds.join(',')}`);
    }

    if (materialsIds.length > 0) {
        params.push(`materialsIds=${materialsIds.join(',')}`);
    }

    if (ratings.length > 0) {
        params.push(`ratings=${ratings.join(',')}`);
    }

    let url = `${API_URL}/api/areas/${areaId}/findings`;
    if (params.length > 0) {
        url += `?${params.join("&")}`;
    }

    const response = await axios(encodeURI(url), {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders(),
        }
    });

    return response?.data?.findings as FindingModel[];
}