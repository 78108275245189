import { Controller, useForm } from "react-hook-form";
import { EntityModel } from "../../models/entity.model";

import { colorToInt, intToColor } from "../../helpers/color.helper";

import DeleteIcon from "../../assets/icons/delete.svg";
import { ColorPicker, DEFAULT_COLORS } from "../ColorPicker";
import { useState } from "react";
import { DeleteModal } from "../DeleteModal";

interface InputOption {
    name: string,
    value: string
}

interface ItemInput {
    name: string,
    label: string,
    type: "text" | "number" | "color" | "select"
    placeholder?: string,
    options?: InputOption[],
    min?: number,
    max?: number,
    value?: string | number
}

interface Props<T> {
    items: T[],
    refreshItems: () => Promise<void>
    createItem: (data: any) => Promise<T>,
    deleteItem: (id: string) => Promise<void>,
    renderItem: (item: T) => JSX.Element,
    inputs: ItemInput[]
}

export function ItemsTable<T extends EntityModel>(props: Props<T>) {
    const { register, handleSubmit, control } = useForm();

    async function createItem(data: any) {
        await props.createItem(data);
        await props.refreshItems();
    }

    async function deleteItem() {
        if (deleteItemId == "") {
            return;
        }

        await props.deleteItem(deleteItemId);
        await props.refreshItems();

        setDeleteItemId("");
    }

    const [deleteItemId, setDeleteItemId] = useState("");

    return <form className="items-table" onSubmit={handleSubmit(createItem)}>
        <table>
            <tbody>
                {props.items.map(item =>
                    <tr key={item.id}>
                        {props.renderItem(item)} <td><button className="icon" type="button" onClick={() => setDeleteItemId(item.id)}><img src={DeleteIcon} /></button></td>
                    </tr>)
                }
            </tbody>
            <tfoot>
                <tr>
                    {props.inputs.map(input => {
                        if (input.type == 'color') {
                            return (
                                <td>
                                    <div className="form-control">
                                        <label>{input.label}</label>
                                        <Controller
                                            control={control}
                                            name={input.name}
                                            defaultValue={"#000000"}
                                            render={({ field: { onChange, value } }) =>
                                                <ColorPicker colors={DEFAULT_COLORS} value={intToColor(value).toUpperCase()} onChange={color => onChange(colorToInt(color))} />
                                            }
                                        />
                                    </div>
                                </td>
                            );
                        }

                        if (input.type == 'select') {
                            return (
                                <td>
                                    <div className="form-control">
                                        <label>{input.label}</label>
                                        <select
                                            {...register(input.name, { value: input?.options?.[0] })}
                                        >
                                            {
                                                input.options &&
                                                input.options.map(option => <option value={option.value}>{option.name}</option>)
                                            }

                                        </select>
                                    </div>
                                </td>
                            );
                        }

                        if (input.type == 'number') {
                            return (<td>
                                <div className="form-control">
                                    <label>{input.label}</label>
                                    <input {...register(input.name, { valueAsNumber: true })} type={input.type} min={input.min ?? -1000} max={input.max ?? 1000} defaultValue={input.value} />
                                </div>
                            </td>);
                        }

                        return (<td>
                            <div className="form-control">
                                <label>{input.label}</label>
                                <input {...register(input.name)} type={input.type} placeholder={input.placeholder ?? ""} />
                            </div>
                        </td>);
                    })}
                    <td><button type="submit">+</button></td>
                </tr>
            </tfoot>

            <DeleteModal
                show={deleteItemId?.length > 0}
                setShow={(s) => setDeleteItemId(s ? "-" : "")}
                alertText={"Opravdu si přejete odstranit tento předmět?"}
                onDelete={deleteItem}
            />
        </table>
    </form>
}