import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FindingTypeModel } from "../../models/finding-type.model";
import { RootState } from "../store";

interface FindingTypeState {
    findingTypes: FindingTypeModel[]
}

const initialState: FindingTypeState = {
    findingTypes: []
}

export const findingTypesSlice = createSlice({
    name: 'findingType',
    initialState,
    reducers: {
        setFindingTypes: (state, action: PayloadAction<FindingTypeModel[]>) => {
            state.findingTypes = action.payload;
        }
    }
});

export const { setFindingTypes } = findingTypesSlice.actions;

export const selectFindingTypes = (state: RootState) => state.findingType.findingTypes;

export default findingTypesSlice.reducer;