import mapboxgl from "mapbox-gl";
import { AreasFillLayer, AreasLayer, AreasLineLayer, FindingsHeatmapLayer, FindingsIconLayer } from "../consts";

export function setMapFindingsFilters(map: mapboxgl.Map, materialIds: string[], findingTypesIds: string[], ratings: number[]) {
    const filters = ['all'] as any[];

    if (materialIds.length > 0) {
        filters.push(['in', ['get', 'materialId'], ['literal', materialIds]]);
    }

    if (findingTypesIds.length > 0) {
        filters.push(['in', ['get', 'findingTypeId'], ['literal', findingTypesIds]]);
    }

    if (ratings.length > 0) {
        filters.push(['in', ['get', 'rating'], ['literal', ratings]]);
    }

    map.setFilter(FindingsIconLayer, filters);
    map.setFilter(FindingsHeatmapLayer, filters);
}

export function setSelectedMapAreaFilter(map: mapboxgl.Map, id: string) {
    const filter = ['==', ['get', 'id'], ['literal', id]];

    map.setLayoutProperty(AreasFillLayer, "visibility", "visible");
    map.setFilter(AreasFillLayer, filter);
}

export function clearSelectedMapAreaFilter(map: mapboxgl.Map) {
    map.setLayoutProperty(AreasFillLayer, "visibility", "none");
}