import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AreaCard } from "../../components/AreaCard";
import { MapAreas } from "../../components/MapAreas";
import { AreaModel } from "../../models/area.model";
import { getAreasGeojson, getAreas } from "../../services/Api";
import { selectUserDefaultSettings } from "../../state/features/user";
import { useAppSelector } from "../../state/hooks";

export function Areas() {
    const navigate = useNavigate();

    const [areas, setAreas] = useState<AreaModel[]>([]);

    const [findingsGeojson, setFindingsGeojson] = useState(undefined);
    const [areasGeojson, setAreasGeojson] = useState(undefined);

    const [areaPreview, setAreaPreview] = useState<AreaModel | undefined>();

    const defaultSettings = useAppSelector(selectUserDefaultSettings);

    async function fetchData() {
        const data = await getAreas();
        setAreas(data);

        const res = await getAreasGeojson();
        setFindingsGeojson(res.findingsGeojson);
        setAreasGeojson(res.areasGeojson);
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (!defaultSettings) {
        return <span>Loading...</span>
    }

    return <div className="page-areas">
        <section className="page-areas-sidebar">
            <div className="page-areas-sidebar-heading">
                <h1>Oblasti</h1>
                <span>({areas.length})</span>
            </div>
            <div className="area-cards">
                <ul>
                    {areas.map(area =>
                        <AreaCard
                            key={area.id}
                            onClick={() => navigate(`/areas/${area.id}`)}
                            area={area}
                            onPreview={() => setAreaPreview(area)}
                            onPreviewLeave={() => setAreaPreview(undefined)}
                        />
                    )}
                </ul>
            </div>
            <div className="page-areas-sidebar-actions">
                <button onClick={() => navigate('/create/area')} className="primary">Vytvořit novou oblast</button>
                {/* <button className="primary" disabled>Nová hledačka</button> */}
            </div>
        </section>
        <section className="page-areas-map">
            <MapAreas
                areasGeojson={areasGeojson}
                findingsGeojson={findingsGeojson}
                showFindingEdit={false}
                showFindingFilters={true}
                areaPreview={areaPreview}
                defaultLocation={{ zoom: defaultSettings.zoom, latitude: defaultSettings.latitude, longitude: defaultSettings.longitude }}
            />
        </section>
    </div>;
}