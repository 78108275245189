import StarIcon from "../../assets/icons/star.svg";
import StarFilledIcon from "../../assets/icons/star-filled.svg";

interface Props {
    rating: number
}

export function FindingRating({ rating }: Props) {
    const ratingListItems = [];

    for (let i = 1; i <= 3; i++) {
        if (i <= rating) {
            ratingListItems.push(<li key={i}><img src={StarFilledIcon} /></li>);
        } else {
            ratingListItems.push(<li key={i}><img src={StarIcon} /></li>);
        }
    }

    return <ul className="finding-rating">{ratingListItems}</ul>;
}