import mapboxgl, { LngLatLike } from "mapbox-gl";
import ReactDOM from 'react-dom/client';

import { useEffect, useRef } from "react";
import { FindingsIconLayer } from "../../consts";
import { FindingPopup } from "../../components/FindingPopup";

import { Provider, useDispatch } from "react-redux";
import { store } from "../../state";
import { resetFindingSelection } from "../../state/features/finding-selection";

export function useFindingsMapPopup() {
    const dispatch = useDispatch();
    const popUpRef = useRef(new mapboxgl.Popup({
        offset: 0,
        closeOnClick: false,
        closeButton: false
    }));

    function createPopup(map: mapboxgl.Map, feature: mapboxgl.MapboxGeoJSONFeature, showFindingEdit: boolean) {
        const popupNode = document.createElement("div")
        const root = ReactDOM.createRoot(popupNode);
        root.render(
            <Provider store={store}>
                <FindingPopup
                    identifier={feature?.properties?.identifier}
                    id={feature?.properties?.id}
                    closePopup={closePopup}
                    showFindingEdit={showFindingEdit}
                />
            </Provider>);

        popUpRef.current
            .setLngLat((feature.geometry as any).coordinates as LngLatLike)
            .setDOMContent(popupNode)
            .addTo(map);

    }

    function createPopupOnClick(map: mapboxgl.Map, point: mapboxgl.Point, showFindingEdit: boolean) {
        const features = map.queryRenderedFeatures(point, { layers: [FindingsIconLayer] });
        const feature = features[0];

        if (feature == undefined) {
            popUpRef.current.remove(); // if no feature found
            return;
        }

        createPopup(map, feature, showFindingEdit);
    }

    function closePopup() {
        dispatch(resetFindingSelection()); // reset current selection
        popUpRef.current.remove();
    }

    return {
        createPopup,
        closePopup,
        createPopupOnClick
    }
}