import { configureStore } from "@reduxjs/toolkit"

import materialReducer from "./features/material";
import findingTypeReducer from "./features/findingType";
import localityReducer from "./features/locality";
import findingFiltersReducer from "./features/finding-filters";
import findingSelectionReducer from "./features/finding-selection";
import authReducer from "./features/auth";
import userReducer from "./features/user";

export const store = configureStore({
    reducer: {
        material: materialReducer,
        findingType: findingTypeReducer,
        locality: localityReducer,
        auth: authReducer,
        findingFilters: findingFiltersReducer,
        findingSelection: findingSelectionReducer,
        user: userReducer
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch