import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getDefaultDetector } from "../../helpers/defaults.helper";
import { CreateFindingModel } from "../../models/contracts/create-finding.model";
import { CreateFindingEntryModel } from "../../models/partial-contracts/create-finding-entry.model";
import { createFindings as createFindingsApi } from "../../services/Api";
import { CreateFindingsValidator } from "../../validators/create-findings.validator";
import { CreateFindingsEntry } from "../CreateFindingsEntry";

import { toast } from "react-toastify";

interface Props {
    areaId: string,
    onCreated: () => void
}

// TODO: needs refactor
export function CreateFindings({ areaId, onCreated }: Props) {

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({ mode: "onBlur" });

    const [createFindings, setCreateFindings] = useState<CreateFindingEntryModel[]>([]);
    const [selectedFindingIndex, setSelectedFindingIndex] = useState(-1);

    function addEmptyFinding() {
        setCreateFindings([...createFindings,
        {
            weight: undefined,
            material: undefined,
            findingType: undefined,

            latitude: 0,
            longitude: 0,
            altitude: 0,
            depth: undefined,

            landRegistry: "",
            county: "",

            images: [],
            description: "",
            rating: 0
        }]);

        setSelectedFindingIndex(createFindings.length);
    }

    function updateFindingAtIndex(finding: CreateFindingEntryModel, index: number) {
        createFindings[index] = finding;
        setCreateFindings([...createFindings]);
    }

    function removeFindingAtIndex(index: number) {
        if (createFindings.length <= 1) return;

        createFindings.splice(index, 1);
        setCreateFindings([...createFindings]);

        if (selectedFindingIndex >= createFindings.length) {
            setSelectedFindingIndex(createFindings.length - 1);
        }
    }

    async function onSubmit(data: any) {
        const actions: CreateFindingModel[] = createFindings.map(createFinding => {
            return {
                created: data.created,
                detector: data.detector,

                findingTypeId: createFinding.findingType?.id ?? "",
                materialId: createFinding.material?.id ?? "",
                localityId: createFinding.locality?.id ?? "",

                latitude: createFinding.latitude,
                longitude: createFinding.longitude,
                altitude: createFinding.altitude,
                depth: createFinding.depth,

                landRegistry: createFinding.landRegistry,
                county: createFinding.county,

                weight: createFinding.weight,
                imageIds: createFinding.images.map(image => image.id),
                description: createFinding.description,
                rating: createFinding.rating
            };
        });

        try {
            await createFindingsApi(areaId, actions);
            toast("Hledačka úspěšně vytvořena.", { type: "success" });
        } catch (_) {
            toast("Hledačka obsahuje prázdná data.", { type: "error" });
            return;
        }

        onCreated();
    }

    useEffect(() => {
        addEmptyFinding();
    }, []);

    return (<div className="create-findings">
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control">
                <label>* Datum</label>
                <input
                    type="date"
                    {...register("created", { valueAsDate: true })}
                    defaultValue={new Date().toISOString().split('T')[0]}
                />
            </div>

            <div className="form-control">
                <label>* Detektor</label>
                <input type="detector" {...register("detector", { value: getDefaultDetector(), ...CreateFindingsValidator.detector })} />
                <span className="form-control-errors">{errors?.detector?.message}</span>
            </div>

            <div className="create-findings-list">
                <label>Nálezy ({createFindings.length})</label>
                <div>
                    <ul style={{ flexFlow: "row", display: "flex" }}>
                        {createFindings.map((_, index) =>
                            <li key={index}>
                                {
                                    index != selectedFindingIndex &&
                                    <button className="primary remove-finding" type="button" onClick={() => removeFindingAtIndex(index)}>X</button>
                                }
                                <button
                                    type="button"
                                    onClick={() => setSelectedFindingIndex(index)}
                                    className={index == selectedFindingIndex ? 'primary' : ''}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>

            {
                selectedFindingIndex >= 0 && selectedFindingIndex < createFindings.length &&
                <div className="create-findings-finding">
                    <CreateFindingsEntry
                        entry={createFindings[selectedFindingIndex]}
                        entryChange={(finding) => updateFindingAtIndex(finding, selectedFindingIndex)}
                        onDate={(date) => { setValue('created', date.toISOString().split('T')[0], { shouldTouch: false }) }}
                    />
                </div>
            }


            <div className="create-findings-action">
                <button type="button" onClick={addEmptyFinding}>Uložit a přidat další nález</button>
                <button type="submit" className="primary">Uložit všechny nálezy</button>
            </div>
        </form>
    </div >);
}