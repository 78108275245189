import { Swiper, SwiperSlide } from "swiper/react";

//@ts-ignore
import SwiperCore, { Pagination } from "swiper/core"

import { ImageModel } from "../../models/image.model";

import "swiper/css";
import "swiper/css/pagination";
import Fancybox from "../Fancybox/component";

SwiperCore.use([Pagination]);

interface Props {
    images: ImageModel[],
    useLightbox: boolean
}

export function PhotoSwiper({ images, useLightbox }: Props) {
    if (useLightbox) {
        return (<Fancybox>
            <Swiper
                pagination={{ clickable: true, dynamicBullets: true, dynamicMainBullets: 6 }}
            >
                {
                    images.map(image =>
                        <SwiperSlide key={image.id}>
                            <a
                                className="fancybox-image"
                                data-fancybox="gallery"
                                data-src={image.url}
                            >
                                <img
                                    className='swiper-image'
                                    src={image.url}
                                />
                            </a>
                        </SwiperSlide>)
                }
            </Swiper>
        </Fancybox>);
    }

    return (
        <Swiper
            pagination={{ clickable: true, dynamicBullets: true, dynamicMainBullets: 6 }}
        >
            {
                images.map(image =>
                    <SwiperSlide key={image.id}>
                        <img
                            className='swiper-image'
                            src={image.url}
                        />
                    </SwiperSlide>)
            }
        </Swiper>
    );
}