// map styles
export const TopoStyle = "/styles/topo.json";
export const NineteenthCentury = "/styles/19century.json";
export const BasicStyle = "/styles/basic.json";
export const GeographicStyle = "/styles/geographic.json";
export const Aerial03 = "/styles/aerial03.json";
export const Aerial06 = "/styles/aerial06.json";
export const Aerial12 = "/styles/aerial12.json";
export const Aerial15 = "/styles/aerial15.json";
export const Aerial18 = "/styles/aerial18.json";
export const Aerial = "/styles/aerial.json";

export const DefaultStyle = TopoStyle;

// map layers
export const FindingsIconLayer = "findings_icon";
export const FindingsHeatmapLayer = "findings_heatmap";

export const AreasLayer = "areas";
export const AreasFillLayer = "areas_fill";
export const AreasLineLayer = "areas_line";