import mapboxgl from "mapbox-gl";
import { useRef } from "react";
import { toast } from "react-toastify";
import { editUserDefaultSettings } from "../../services/Api/users";
import { setUserDefaultSettings } from "../../state/features/user";
import { useAppDispatch } from "../../state/hooks";
import { Map } from "../Map";

export function DefaultLocationSettings() {
    const dispatch = useAppDispatch();

    const mapInstance = useRef<mapboxgl.Map | undefined>(undefined);

    function onInit(map: mapboxgl.Map) {
        mapInstance.current = map;
    }

    async function saveLocation() {
        const map = mapInstance.current;
        if (!map) return;

        const zoom = map.getZoom();
        const center = map.getCenter();

        const defaultSettings = await editUserDefaultSettings({
            zoom,
            latitude: center.lat,
            longitude: center.lng
        });
        dispatch(setUserDefaultSettings(defaultSettings));

        toast("Výchozí lokace uložena.", { type: "success" });
    }

    return <div className="default-location-settings">
        <h2>Výchozí lokace</h2>
        <Map
            init={onInit}
        />
        <button className="primary" type="button" onClick={saveLocation}>Uložit výchozí lokaci</button>
    </div>;
}