import { EmailValidator } from "./email.validator";

export const LoginValidator = {
    email: EmailValidator,
    password: {
        required: "Prosím zadejte heslo.",
        minLength: {
            value: 6,
            message: "Heslo musí obsahovat alespoň 6 znaků."
        },
        maxLength: {
            value: 30,
            message: "Heslo nesmí obsahovat více jak 30 znaků."
        }
    }
}