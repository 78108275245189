import bbox from "@turf/bbox";
import mapboxgl, { LngLat, LngLatLike } from "mapbox-gl";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Map } from "../Map/component";

import { useFindingsMapPopup } from "../../hooks/map/findings-popup.hook";
import { useAppSelector } from "../../state/hooks";
import { selectFindingFilters } from "../../state/features/finding-filters";
import { clearSelectedMapAreaFilter, setSelectedMapAreaFilter, setMapFindingsFilters } from "../../helpers/filters.helper";
import { selectFindingSelection } from "../../state/features/finding-selection";
import { AreaModel } from "../../models/area.model";
import { FindingsIconLayer } from "../../consts";
import { UserDefaultLocation } from "../../models/users/user-default-location.model";

interface Props {
    findingsGeojson?: any
    areasGeojson?: any,
    showFindingEdit: boolean,
    showFindingFilters: boolean,
    areaPreview?: AreaModel,
    defaultLocation?: UserDefaultLocation
}

export function MapAreas({ areasGeojson, findingsGeojson, showFindingEdit, showFindingFilters, areaPreview, defaultLocation }: Props) {
    const [map, setMap] = useState<mapboxgl.Map | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [styleReload, setStyleReload] = useState(0);

    function setAreasData() {
        if (map == undefined) return;
        if (areasGeojson == undefined) return;

        const source = map.getSource("areas");
        if (source?.type == "geojson") {
            source.setData(areasGeojson as any);
        }

        const bounds = bbox(areasGeojson);
        if (bounds[0] != Infinity && defaultLocation == undefined) {
            map.fitBounds(bounds as any, {
                padding: 100,
                animate: false,

            });

            map.fitBounds(bounds as any, {
                padding: 60,
                animate: true,
                duration: 1000
            });

            setLoading(false);
        }
    }

    function setFindingsData() {
        if (map == undefined) return;
        if (findingsGeojson == undefined) return;

        const source = map.getSource("findings");
        if (source?.type == "geojson") {
            source.setData(findingsGeojson as any);
        }
    }

    useEffect(() => {
        setAreasData();
    }, [areasGeojson]);

    useEffect(() => {
        setFindingsData();
    }, [findingsGeojson]);

    useEffect(() => {
        setAreasData();
        setFindingsData();
    }, [styleReload]);

    // findings filters
    const filters = useAppSelector(selectFindingFilters);
    useEffect(() => {
        if (!map) return;
        setMapFindingsFilters(map, filters.materialsIds, filters.findingTypesIds, filters.ratings);
    }, [filters]);

    // finding selection
    const findingSelection = useAppSelector(selectFindingSelection);
    useEffect(() => {
        if (!map) return;
        if (!findingSelection) {
            closePopup();
            return;
        }

        const location = new mapboxgl.LngLat(findingSelection.finding.longitude, findingSelection.finding.latitude);
        if (findingSelection.selected) {
            map.once("idle", () => {
                const point = map.project(location);
                createPopupOnClick(map, point, showFindingEdit);
            });
            map.flyTo({ center: location, zoom: 16, duration: 300 });
        } else {
            const point = map.project(location);
            createPopupOnClick(map, point, showFindingEdit);
        }

    }, [findingSelection]);

    // area preview selection
    useEffect(() => {
        if (!map) return;

        if (areaPreview) {
            setSelectedMapAreaFilter(map, areaPreview.id)
        } else {
            clearSelectedMapAreaFilter(map);
        }

    }, [areaPreview]);


    const { createPopupOnClick, closePopup } = useFindingsMapPopup();

    function mapInit(mapInstance: mapboxgl.Map) {
        mapInstance.once("load", () => {
            setMap(mapInstance);

            // jump to pre-set location
            // TODO: refactor to map component default location event
            if (defaultLocation) {
                mapInstance.jumpTo({ center: new LngLat(defaultLocation.longitude, defaultLocation.latitude), zoom: defaultLocation.zoom });
            }
            setLoading(false);
        });

        mapInstance.on("styleload", () => {
            setStyleReload(Date.now());
        });

        mapInstance.on("click", FindingsIconLayer, e => {
            createPopupOnClick(mapInstance, e.point, showFindingEdit);
        });
    }

    return (<Map
        loading={loading}
        init={mapInit}
        showMapStyles={true}
        showHeatmapToggle={true}
        showAreasToggle={true}
        showFindingFilters={showFindingFilters}
        defaultLocation={defaultLocation}
    />);
}