export const CreateFindingsValidator = {
    detector: {
        required: "Název detektoru nesmí být prázdný.",
        minLength: {
            value: 2,
            message: "Název detektoru musí obsahovat alespoň 2 znaky."
        },
        maxLength: {
            value: 30,
            message: "Název detektoru nesmí být delší jak 30 znaků."
        }
    }
}