import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { saveAccessToken } from "../../helpers/token.helper";
import { RootState } from "../store";

interface AuthState {
    token: string
}

const initialState: AuthState = {
    // TODO: implement default token from localStorage
    token: ""
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            const token = action.payload;

            saveAccessToken(token);
            state.token = token;
        }
    }
});

export const { setToken } = authSlice.actions;

export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
