import { useEffect, useState } from "react";
import { FindingModel } from "../../models/finding.model";
import { getFinding } from "../../services/Api";
import { resetFindingSelection, setFindingEditing } from "../../state/features/finding-selection";
import { useAppDispatch } from "../../state/hooks";
import { FindingRating } from "../FindingRating";
import { PhotoSwiper } from "../PhotoSwiper";

import FindingPlaceholder from "../../assets/images/no-image.png";

interface Props {
    identifier: number,
    id: string,
    closePopup: () => void,
    showFindingEdit: boolean
}

export function FindingPopup({ showFindingEdit, id, closePopup }: Props) {
    const dispatch = useAppDispatch();

    const [finding, setFinding] = useState<FindingModel>();

    async function fetchFinding() {
        const result = await getFinding(id);
        setFinding(result);
    }

    useEffect(() => {
        fetchFinding();
    }, []);

    function handleEdit() {
        if (!finding) return;

        closePopup();
        dispatch(setFindingEditing(finding));
    }

    if (!finding) {
        return (<div className="findings-popup">
            <button onClick={closePopup} className="findings-popup-close primary">X</button>
            <span>Načítání...</span>
        </div>)
    }

    return (<div className="findings-popup">
        <button onClick={closePopup} className="findings-popup-close primary">X</button>
        <div className="findings-popup-heading">
            <h2>{finding?.findingType?.identifier}</h2>
            <span>{finding?.identifier}</span>
        </div>
        <div><FindingRating rating={finding.rating} /></div>
        {
            finding.images.length > 0 &&
            <PhotoSwiper images={finding.images} useLightbox={true} />
        }
        {
            finding.images.length <= 0 &&
            <img src={FindingPlaceholder} />
        }
        <ul>
            <li><b>Materiál:</b> {finding.material.identifier}, {finding.weight ?? '-'} g</li>
            <li><b>Nadmořská výška:</b> {finding.altitude.toFixed(1)}m</li>
        </ul>
        <div className="findings-popup-description">
            <b>Popis:</b>
            <p>{finding.description}</p>
        </div>
        {
            showFindingEdit &&
            <div className="findings-popup-actions">
                <button className="small" onClick={handleEdit}>Upravit nález</button>
            </div>
        }
    </div>);
}