import { forwardRef, useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { getPrintTagDate } from "../../helpers/date.helper";
import { FindingModel } from "../../models/finding.model";
import { LargePrintTagModel, LargePrintTagSettingsModel, MapPosition } from "../../models/print/large-print-tag.model";
import { selectCurrentUser } from "../../state/features/user";
import { useAppSelector } from "../../state/hooks";
import { Map } from "../Map";

interface Props {
    // finding: FindingModel,
    printData: LargePrintTagModel,
    setImageTransform?: (index: number, x: number, y: number, scale: number) => void,
    setMapPosition?: (lat: number, lon: number, zoom: number) => void,

    area: number,
    print: boolean
}

export const LargePrintTag = forwardRef(({ printData, area, setImageTransform, setMapPosition, print }: Props, ref: any) => {
    const currentUser = useAppSelector(selectCurrentUser);
    const { finding, settings, } = printData;

    function getDate() {
        return getPrintTagDate(finding);
    }

    function getImageContent(index: number) {
        if (finding.images[index] == undefined) {
            return <div></div>;
        }

        return (<TransformWrapper
            key={`${settings.imageVariant}-${index}`}
            limitToBounds={false}
            centerZoomedOut={true}
            initialScale={settings.images[index].scale}
            initialPositionX={settings.images[index].x}
            initialPositionY={settings.images[index].y}
            pinch={{ step: 0.5 }}
            doubleClick={{ mode: "reset" }}
            onPanningStop={({ state }) => setImageTransform?.(index, state.positionX, state.positionY, state.scale)}
            onZoomStop={({ state }) => setImageTransform?.(index, state.positionX, state.positionY, state.scale)}
        >
            <TransformComponent>
                <img src={finding.images[index].url} />
            </TransformComponent>
        </TransformWrapper>);
    }

    function renderImages() {
        if (settings.imageVariant == "double") {
            return (
                <div className="double">
                    {
                        getImageContent(0)
                    }
                    {
                        getImageContent(1)
                    }
                </div>
            );
        } else {
            return (
                <div className="triple">
                    {
                        getImageContent(0)
                    }
                    {
                        getImageContent(1)
                    }
                    {
                        getImageContent(2)
                    }
                </div>
            );
        }
    }

    const [lastMapPosition, setLastMapPosition] = useState<MapPosition>({
        latitude: 0, longitude: 0, zoom: 0
    });

    useEffect(() => {
        setMapPosition?.(lastMapPosition.latitude, lastMapPosition.longitude, lastMapPosition.zoom);
    }, [lastMapPosition]);

    // return (<div className={`large-print-tag ${print == true ? 'print' : 'preview'}`} ref={ref}>
    return (<div className={`large-print-tag ${print == true ? '' : 'preview'}`} ref={ref}>
        <table className={`large-print-tag-table area-${area}`}>
            <tbody>
                <tr>
                    <td>Datum</td>
                    <td>{getDate()}</td>
                    <td>Evidenční číslo</td>
                    <td>{finding.identifier}</td>
                </tr>
                <tr>
                    <td>Okres</td>
                    <td>{finding.county?.replaceAll("okres", "")}</td>
                    <td>Katastr</td>
                    <td>{finding.landRegistry}</td>
                </tr>
                <tr>
                    <td>Předmět</td>
                    <td colSpan={3}>{finding.findingType?.identifier}</td>
                </tr>
                <tr>
                    <td>Materiál</td>
                    <td>{finding.material?.identifier}</td>
                    <td>Hmotnost</td>
                    <td>{finding.weight?.toFixed(1)} g</td>
                </tr>
                <tr>
                    <td>Lokalita</td>
                    <td >{finding.locality?.identifier}</td>
                    <td>Hloubka</td>
                    {
                        finding.depth === undefined &&
                        <td></td>
                    }
                    {
                        finding.depth !== undefined &&
                        <td>{finding.depth?.toFixed(0)} cm</td>
                    }
                </tr>
                <tr className="large-print-tag-table-description">
                    <td>Poznámka</td>
                    <td colSpan={3} rowSpan={2}>{finding.description}</td>
                </tr>
                <tr>
                    <td colSpan={1}></td>
                </tr>
                <tr className="large-print-tag-table-map">
                    <td colSpan={4}>
                        <Map init={map => {
                            map.on("dragend", _ => {
                                const center = map.getCenter();
                                // setMapPosition?.(center.lat, center.lng, map.getZoom());
                                setLastMapPosition({ latitude: center.lat, longitude: center.lng, zoom: map.getZoom() });
                            });

                            map.on("load", () => {
                                const source = map.getSource("findings");
                                if (source?.type == "geojson") {
                                    source.setData({
                                        type: "FeatureCollection",
                                        features: [
                                            {
                                                type: "Feature",
                                                properties: {
                                                    "id": "4815f346-906d-4fb5-9448-fe08c8925a96",
                                                    "identifier": finding.identifier,
                                                    "color": "#B80000",
                                                    "icon": "circle",
                                                    "iconSize": 2,
                                                    "materialId": finding.material.id,
                                                    "findingTypeId": finding.findingType.id,
                                                    "rating": 0
                                                },
                                                geometry: {
                                                    type: "Point",
                                                    coordinates: [finding.longitude, finding.latitude]
                                                }
                                            }
                                        ]
                                    });
                                }

                                map.setZoom(settings.mapPosition.zoom);
                                map.setCenter({ lat: settings.mapPosition.latitude, lng: settings.mapPosition.longitude });
                            });
                        }}></Map>

                        <div className="coordinates">
                            {
                                finding.altitude == 0 &&
                                <span>GPS: {finding.latitude.toFixed(7)}, {finding.longitude.toFixed(7)}</span>
                            }
                            {
                                finding.altitude != 0 &&
                                <span>GPS: {finding.latitude.toFixed(7)}, {finding.longitude.toFixed(7)} ({finding.altitude.toFixed(1)} m.n.m.)</span>
                            }
                        </div>
                    </td>
                </tr>
                {/* <tr>
                    <td>GPS</td>
                    <td colSpan={2}>
                        {finding.latitude.toFixed(7)}, {finding.longitude.toFixed(7)}
                    </td>
                    <td colSpan={1}>
                        {finding.altitude.toFixed(1)} m.n.m.
                    </td>
                </tr> */}
                <tr>
                    <td colSpan={4} className="large-print-tag-table-images">
                        {
                            renderImages()
                        }
                    </td>
                </tr>
                <tr>
                    <td>Nálezce</td>
                    <td colSpan={3}>{currentUser?.fullName ?? '-'}</td>
                </tr>
                <tr>
                    <td>Detektor</td>
                    <td colSpan={3}>{finding.detector}</td>
                </tr>
            </tbody>
        </table>
    </div>);
});