import axios from "axios";
import { API_URL } from "../../config";
import { LandInfo } from "../../models/nominatim/land-info.model";
import { buildAuthorizationHeaders } from "./headers";

export async function getLandInfo(longitude: number, latitude: number) {
    const response = await axios(`${API_URL}/api/nominatim/land_info?longitude=${longitude}&latitude=${latitude}`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return response.data as LandInfo;
}