import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FindingModel } from "../../models/finding.model";
import { getFindings } from "../../services/Api";
import { selectFindingFilters } from "../../state/features/finding-filters";

export function useAreaFindings(areaId?: string) {
    const findingFilters = useSelector(selectFindingFilters);
    const [findings, setFindings] = useState<FindingModel[]>([]);

    async function tryFetchFindings() {
        // short-circuit undefined area
        if (!areaId) return;

        const res = await getFindings(areaId, findingFilters.orderBy, findingFilters.ascending, findingFilters.findingTypesIds, findingFilters.materialsIds, findingFilters.ratings);
        setFindings(res);
    }

    useEffect(() => {
        tryFetchFindings();
    }, [areaId, findingFilters]);

    return {
        findings,
        fetchFindings: tryFetchFindings
    };
}