import { ImageWithMetaModel } from "../../models/image-with-meta.model";
import { ImageModel } from "../../models/image.model";
import { ImageEntry } from "../ImageEntry";
import ImageInput from "../ImageInput/component";

interface Props {
    images: ImageWithMetaModel[],
    uploadFunction: (image: File | Blob) => Promise<ImageModel>,
    setImages: (images: ImageWithMetaModel[]) => void,
    deleteImage: (image: ImageModel) => void,
    onCoordinates?: (longitude: number, latitude: number, altitude: number) => void,
    onDate?: (date: Date) => void,
    ignoreMeta?: boolean
}

export function ImagesEditor({ images, uploadFunction, setImages, deleteImage, onCoordinates, onDate, ignoreMeta }: Props) {
    function moveImages(from: number, to: number) {
        const arr = images.filter((_, index) => index != from);
        arr.splice(to, 0, images[from]);

        setImages(arr);
    }

    return (<ul className="images-editor">
        <li>
            <ImageInput
                uploadFunction={uploadFunction}
                onUpload={(image) => setImages([image, ...images])}
                ignoreMeta={ignoreMeta}
            />
        </li>
        {
            images.map((image, index) =>
                <li key={image.id}>
                    <ImageEntry
                        image={image}
                        index={index}
                        onDelete={() => deleteImage(image)}
                        onData={onCoordinates && onDate ? () => {
                            if (image?.meta) {
                                onDate?.(image.meta?.date);
                                onCoordinates?.(image.meta?.longitude, image.meta?.latitude, image.meta?.altitude);
                            }
                        } : undefined}
                        onMove={moveImages}
                    />
                </li>)
        }
    </ul>);
}