export function colorToInt(hex: string): number {
    if (hex.startsWith('#')) {
        hex = hex.slice(1);
    }

    return Number(`0x${hex}`);
}

export function intToColor(value: number): string {
    return `#${value.toString(16).padStart(6, "0")}`;
}