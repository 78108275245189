import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DefaultLocationSettings } from "../../components/DefaultLocationSettings";
import { DetectorSettings } from "../../components/DetectorSettings";
import { ItemsTable } from "../../components/ItemsTable";
import { UserSettings } from "../../components/UserSettings";
import { intToColor } from "../../helpers/color.helper";
import { useLoadFindingTypes } from "../../hooks/findingTypes/load-finding-types.hook";
import { useLoadLocalities } from "../../hooks/localities/localities.hook";
import { useLoadMaterials } from "../../hooks/materials/load-materials.hook";
import { LocalityModel } from "../../models/locality.model";
import { createFindingType, createMaterial, deleteFindingType, deleteMaterial } from "../../services/Api";
import { createLocality, deleteLocality } from "../../services/Api/localities";
import { seedUser } from "../../services/Api/users";
import { selectFindingTypes } from "../../state/features/findingType";
import { selectLocalities } from "../../state/features/locality";
import { selectMaterials } from "../../state/features/material";

export function Settings() {
    const materials = useSelector(selectMaterials);
    const findingTypes = useSelector(selectFindingTypes);
    const localities = useSelector(selectLocalities);

    const loadMaterials = useLoadMaterials();
    const loadFindingTypes = useLoadFindingTypes();
    const loadLocalities = useLoadLocalities();

    async function handleSeedUser() {
        await seedUser();
        toast.success("Generace výchozích hodnot byla dokončena (see network TAB for further details).");
    }

    return <div className="page-settings">
        <h1>Nastavení</h1>

        <section className="page-settings-defaults">
            <UserSettings />
            <DetectorSettings />
        </section>

        <section className="page-settings-items">
            <div>
                <h2>Předměty</h2>
                <ItemsTable
                    items={findingTypes}
                    refreshItems={loadFindingTypes}
                    createItem={(data) => createFindingType(data.identifier, data.icon, data.iconSize)}
                    deleteItem={deleteFindingType}
                    renderItem={findingType => <><td>{findingType.identifier}</td> <td>{findingType.icon}</td><td>{findingType.iconSize}</td></>}
                    inputs={[
                        { name: "identifier", label: "* Název", type: "text", placeholder: "Pazourek" },
                        { name: "icon", label: "* Ikona", type: "select", options: [{ name: "Kruh", value: "circle" }, { name: "Kříž", value: "cross" }, { name: "Kosočtverec", value: "diamond" }, { name: "Čtverec", value: "square" }, { name: "Trojúhelník", value: "triangle" }] },
                        { name: "iconSize", label: "* Velikost", type: "number", min: 1, max: 3, value: 2 },
                    ]}
                />
            </div>

            <div>
                <h2>Materiály</h2>
                <ItemsTable
                    items={materials}
                    refreshItems={loadMaterials}
                    createItem={(data) => createMaterial(data.identifier, data.color)}
                    deleteItem={deleteMaterial}
                    renderItem={material => <><td>{material.identifier}</td> <td><div className="item-color" style={{ backgroundColor: intToColor(material.color) }}></div></td></>}
                    inputs={[
                        { name: "identifier", label: "* Název", type: "text", placeholder: "Měď" },
                        { name: "color", label: "* Barva", type: "color" }
                    ]}
                />
            </div>

            <div>
                <h2>Lokality</h2>
                <ItemsTable
                    items={localities}
                    refreshItems={loadLocalities}
                    createItem={data => createLocality(data.identifier)}
                    deleteItem={deleteLocality}
                    renderItem={locality => <><td>{locality.identifier}</td></>}
                    inputs={[
                        { name: "identifier", label: "* Název", type: "text", placeholder: "Les" },
                    ]}
                />
            </div>
        </section>

        <section className="page-settings-location">
            <DefaultLocationSettings />
        </section>

        <section>
            <h2>Vygenerování výchozích hodnot uživatele</h2>
            <button onClick={handleSeedUser}>Vygenerovat výchozí hodnoty</button>
        </section>
    </div>
}