import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import { FindingModel } from "../../models/finding.model";
import { ImageModel } from "../../models/image.model";
import { deleteFinding, editFinding, uploadFindingImage } from "../../services/Api";
import { selectFindingTypes } from "../../state/features/findingType";
import { selectMaterials } from "../../state/features/material";
import { useAppSelector } from "../../state/hooks";
import { ItemsSelect } from "../ItemsSelect";
import { SmallPrintTag } from "../SmallPrintTag";

import PrintIcon from "../../assets/icons/print.svg";
import DocumentIcon from "../../assets/icons/document.svg";
import DeleteIcon from "../../assets/icons/delete.svg";

import { ImagesEditor } from "../ImagesEditor";
import { DeleteModal } from "../DeleteModal";
import { FindingRatingInput } from "../FindingRatingInput";
import { LargePrintTag } from "../LargePrintTag";
import { PrintAreaModal } from "../PrintAreaModal";
import { ImageWithMetaModel } from "../../models/image-with-meta.model";
import { LargePrintTagPreviewModal } from "../LargePrintTagPreviewModal";
import { LargePrintTagSettingsModel } from "../../models/print/large-print-tag.model";

interface Props {
    finding: FindingModel,
    onEdit: () => void
}

export function EditFinding({ finding, onEdit }: Props) {
    const materials = useAppSelector(selectMaterials);
    const findingTypes = useAppSelector(selectFindingTypes);

    const [images, setImages] = useState(finding.images.map(i => i as ImageWithMetaModel));
    const { register, handleSubmit, control } = useForm();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPrintAreaModal, setShowPrintAreaModal] = useState(false);
    const [showPrintPreviewModal, setShowPrintPreviewModal] = useState(false);

    // TODO: move to separate component?
    const [largePrintTagSettings, setLargePrintTagSettings] = useState<LargePrintTagSettingsModel>({
        imageVariant: "double",
        images: [
            {
                scale: 1, x: 0, y: 0
            },
            {
                scale: 1, x: 0, y: 0
            },
            {
                scale: 1, x: 0, y: 0
            }
        ],
        mapPosition: {
            latitude: finding.latitude,
            longitude: finding.longitude,
            zoom: 12
        }
    });

    async function onSubmit(data: any) {
        await editFinding(finding.id, {
            longitude: finding.longitude,
            latitude: finding.latitude,
            altitude: finding.altitude,

            landRegistry: finding.landRegistry,
            county: finding.county,

            imageIds: images.map(i => i.id),

            findingTypeId: data.findingType.id,
            materialId: data.material.id,
            localityId: finding.locality.id, // TODO: make editable!

            detector: data.detector,
            weight: data.weight,

            rating: data.rating,
            description: data.description
        });

        onEdit();
    }

    async function handleDeleteFinding() {
        // TODO: deleting current finding (lock other actions)
        await deleteFinding(finding.id);
        onEdit();
    }

    async function handleDeleteImage(image: ImageModel) {
        setImages(images.filter(i => i.id != image.id));
        // await deleteFindingImage(image.id);
    }

    // small print tag logic
    const smallPrintTag = useRef(null);

    const handlePrintFindingSmall = useReactToPrint({
        content: () => smallPrintTag.current,
        pageStyle: "@page { size: 3.81cm 2.51cm; }"
    });

    // large print tag logic
    const largePrintTag = useRef(null);
    const [largePrintTagArea, setLargePrintTagArea] = useState(-1);

    const handlePrintFindingLarge = useReactToPrint({
        content: () => largePrintTag.current,
        pageStyle: "@page { size: A4; }",
        onBeforeGetContent: () => {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 1000);
            });
        },
        onAfterPrint() {
            setLargePrintTagArea(-1);
        },
    });

    function printFindingLarge(area: number) {
        setLargePrintTagArea(area);
    }

    useEffect(() => {
        if (largePrintTagArea < 0) return;

        handlePrintFindingLarge();
        setShowPrintAreaModal(false);
    }, [largePrintTagArea]);

    return (<div className="edit-finding">
        <h2>Upravit nález</h2>

        <div className="edit-finding-images">
            <label>Fotky ({images.length})</label>
            <ImagesEditor
                images={images}
                setImages={setImages}
                uploadFunction={uploadFindingImage}
                deleteImage={handleDeleteImage}
            />
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control">
                <label>Hmotnost (g)</label>
                <input
                    type="number"
                    step={0.01}
                    {...register("weight", { valueAsNumber: true, value: finding.weight })} />
            </div>

            <div className="form-control">
                <label>Detektor</label>
                <input type="text" {...register("detector", { value: finding.detector })} />
            </div>

            <div className="form-control">
                <label>Hodnocení</label>
                <Controller
                    control={control}
                    name="rating"
                    defaultValue={finding.rating}
                    render={({ field: { onChange, value } }) =>
                        <FindingRatingInput
                            value={value}
                            onChange={onChange} />}
                />
            </div>

            <div className="form-control">
                <label>Popis</label>
                <input type="text" {...register("description", { value: finding.description })} />
            </div>

            <div className="form-control">
                <label>Materiál</label>
                <Controller
                    control={control}
                    name="material"
                    defaultValue={finding.material}
                    render={({ field: { onChange, value } }) =>
                        <ItemsSelect
                            value={value}
                            items={materials}
                            nameExtractor={item => item.identifier}
                            onChange={item => onChange(item)}
                        />
                    }
                />
            </div>

            <div className="form-control">
                <label>Předmět</label>
                <Controller
                    control={control}
                    name="findingType"
                    defaultValue={finding.findingType}
                    render={({ field: { onChange, value } }) =>
                        <ItemsSelect
                            value={value}
                            items={findingTypes}
                            nameExtractor={item => item.identifier}
                            onChange={item => onChange(item)}
                        />
                    }
                />
            </div>

            <button type="submit" className="primary">Upravit nález</button>
        </form>

        <div className="edit-finding-actions">
            <button type="button" className="icon" onClick={handlePrintFindingSmall} title="Tisknout malý štítek.">
                <img src={PrintIcon} />
            </button>

            <button type="button" className="icon" onClick={() => setShowPrintPreviewModal(true)} title="Tisknout velký štítek.">
                <img src={DocumentIcon} />
            </button>

            <button type="button" className="icon" onClick={() => setShowDeleteModal(true)} title="Odstranit nález.">
                <img src={DeleteIcon} />
            </button>
        </div>

        <DeleteModal
            show={showDeleteModal}
            setShow={setShowDeleteModal}
            alertText={`Opravdu si přejete odstranit nález ${finding.identifier}?`}
            onDelete={handleDeleteFinding}
        />

        <PrintAreaModal
            show={showPrintAreaModal}
            setShow={setShowPrintAreaModal}
            handleSelectedPrintArea={printFindingLarge}
        />

        <LargePrintTagPreviewModal show={showPrintPreviewModal} setShow={setShowPrintPreviewModal} printData={{ finding, settings: largePrintTagSettings }} setPrintSettings={setLargePrintTagSettings} handlePrint={() => { setShowPrintPreviewModal(false); setShowPrintAreaModal(true); }} />

        <SmallPrintTag finding={finding} ref={smallPrintTag} />

        {
            largePrintTagArea >= 0 &&
            <LargePrintTag print={true} printData={{ finding, settings: largePrintTagSettings }} area={largePrintTagArea} ref={largePrintTag} />
        }
    </div >);
}