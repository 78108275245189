import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deflate } from "zlib";
import { FindingModel } from "../../models/finding.model";
import { RootState } from "../store";

interface FindingSelection {
    finding: FindingModel,
    selected: boolean
};

interface FindingSelectionState {
    selection: FindingSelection | undefined,
    editing: FindingModel | undefined,
};

const initialState: FindingSelectionState = {
    selection: undefined,
    editing: undefined
};

export const findingSelectionSlice = createSlice({
    name: "findingSelection",
    initialState,
    reducers: {
        setFindingSelection: (state, action: PayloadAction<FindingSelection>) => {
            state.selection = action.payload;
        },
        resetFindingSelection: (state) => {
            state.selection = undefined;
        },
        setFindingEditing: (state, action: PayloadAction<FindingModel>) => {
            state.editing = action.payload;
        },
        resetFindingEditing: (state) => {
            state.editing = undefined;
        }
    }
});

export const {
    setFindingSelection,
    resetFindingSelection,
    setFindingEditing,
    resetFindingEditing
} = findingSelectionSlice.actions;

export const selectFindingSelection = (state: RootState) => state.findingSelection.selection;
export const selectFindingEditing = (state: RootState) => state.findingSelection.editing;

export default findingSelectionSlice.reducer;