import mapboxgl from "mapbox-gl";
import { MutableRefObject, useEffect, useState } from "react";

interface Props {
    map: mapboxgl.Map | undefined,
    layers: string[],
    defaultActive: boolean,
    icon: string
}

export function MapLayerToggle({ map, layers, defaultActive, icon }: Props) {
    const [active, setActive] = useState(defaultActive);

    function toggleLayer() {
        for (const layer of layers) {
            map?.setLayoutProperty(layer, "visibility", !active ? "visible" : "none");
        }
        setActive(!active);
    }

    useEffect(() => {
        map?.on("styleload", () => {
            setActive(defaultActive);
        });
    }, [map]);

    return (<button
        className={`icon ${active ? "active" : ""}`}
        onClick={toggleLayer}
    >
        <img src={icon} />
    </button>)
}