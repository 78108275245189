import mapboxgl from "mapbox-gl";
import { useEffect, useState } from "react";
import { setMapFindingsFilters } from "../../helpers/filters.helper";
import { selectFindingTypes } from "../../state/features/findingType";
import { selectMaterials } from "../../state/features/material";
import { useAppSelector } from "../../state/hooks";
import { FindingsItemsFilter } from "../FindingsItemsFilter";
import { FindingsRatingsFilter } from "../FindingsRatingsFilter";

import DropdownIcon from "../../assets/icons/dropdown.svg";

interface Props {
    map: mapboxgl.Map | undefined
}

export function MapFindingFilters({ map }: Props) {
    const findingTypes = useAppSelector(selectFindingTypes);
    const materials = useAppSelector(selectMaterials);

    const [selectedFindingTypes, setSelectedFindingTypes] = useState([] as string[]);
    const [selectedMaterials, setSelectedMaterials] = useState([] as string[]);
    const [selectedRatings, setSelectedRatings] = useState([] as number[]);

    useEffect(() => {
        if (!map) return;
        setMapFindingsFilters(map, selectedMaterials, selectedFindingTypes, selectedRatings);
    }, [
        selectedFindingTypes,
        selectedMaterials,
        selectedRatings
    ]);

    return <div className="map-finding-filters">
        <div>
            <span>
                {
                    selectedFindingTypes.length > 0 &&
                    <div className="map-finding-filters-active"></div>
                }
                Typ
                <img src={DropdownIcon} />
            </span>
            <FindingsItemsFilter
                items={findingTypes}
                nameExtractor={f => f.identifier}
                onClickOutside={() => { }}
                selectedIds={selectedFindingTypes}
                setFilters={setSelectedFindingTypes}
            />
        </div>
        <div>
            <span>
                {
                    selectedMaterials.length > 0 &&
                    <div className="map-finding-filters-active"></div>
                }
                Materiály
                <img src={DropdownIcon} />
            </span>
            <FindingsItemsFilter
                items={materials}
                nameExtractor={m => m.identifier}
                onClickOutside={() => { }}
                selectedIds={selectedMaterials}
                setFilters={setSelectedMaterials}
            />
        </div>
        <div>
            <span>
                {
                    selectedRatings.length > 0 &&
                    <div className="map-finding-filters-active"></div>
                }
                Hodnocení
                <img src={DropdownIcon} />
            </span>
            <FindingsRatingsFilter
                selectedRatings={selectedRatings}
                setSelectedRatings={setSelectedRatings}
                onClickOutside={() => { }}
            />
        </div>
    </div>;
}