import { getMaterials } from "../../services/Api";
import { setMaterials } from "../../state/features/material";
import { useAppDispatch } from "../../state/hooks";

export function useLoadMaterials() {
    const dispatch = useAppDispatch();

    async function loadMaterials() {
        const materials = await getMaterials();
        dispatch(setMaterials(materials));
    }

    return loadMaterials;
}