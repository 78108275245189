import React, { MouseEvent, useState } from "react";
import { FindingModel } from "../../models/finding.model";
import { resetFindingFilters, selectFindingFilters, selectFindingFiltersActive, setFindingTypesFilter, setMaterialsFilter, setOrderByFindingFilter, setRatingsFilter } from "../../state/features/finding-filters";
import { selectFindingTypes } from "../../state/features/findingType";
import { selectMaterials } from "../../state/features/material";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { FindingsItemsFilter } from "../FindingsItemsFilter";

import FilterUpIcon from "../../assets/icons/filter-up.svg";
import FilterDownIcon from "../../assets/icons/filter-down.svg";
import { resetFindingSelection, selectFindingSelection, setFindingSelection } from "../../state/features/finding-selection";
import { FindingRating } from "../FindingRating";
import { FindingsRatingsFilter } from "../FindingsRatingsFilter";
import { FindingsTableRowPrint } from "../FindingsTableRowPrint";

interface Props {
    findings: FindingModel[]
}

export function FindingsTable({ findings }: Props) {
    const dispatch = useAppDispatch();

    const materials = useAppSelector(selectMaterials);
    const findingTypes = useAppSelector(selectFindingTypes);

    const filters = useAppSelector(selectFindingFilters);
    const filtersActive = useAppSelector(selectFindingFiltersActive);

    const [showMultiselect, setShowMultiselect] = useState("");
    function selectMultiselectHandler(e: MouseEvent, multiselect: string) {
        e.stopPropagation();
        setShowMultiselect(showMultiselect == multiselect ? "" : multiselect);
    }

    function handleSetOrderBy(field: string) {
        if (filters.orderBy == field && !filters.ascending) {
            dispatch(setOrderByFindingFilter({ orderBy: "", ascending: true }));
            return;
        }

        dispatch(setOrderByFindingFilter({
            orderBy: field,
            ascending: field == filters.orderBy ? !filters.ascending : true
        }));
    }

    // finding selection
    const selection = useAppSelector(selectFindingSelection);

    function handlePreview(e: React.MouseEvent, finding: FindingModel) {
        if (selection?.selected) return; // short-circuit if finding selected
        if (selection?.finding == finding) return; // short-circuit if same finding previewed

        dispatch(setFindingSelection({ finding, selected: false }));
    }

    function handlePreviewLeave(e: React.MouseEvent) {
        if (selection?.selected) return; // short-circuit if finding selected
        dispatch(resetFindingSelection());
    }

    function handleSelect(e: React.MouseEvent, finding: FindingModel) {
        dispatch(setFindingSelection({
            finding,
            selected: selection?.finding == finding && !selection.selected || selection?.finding != finding
        }));
    }

    function renderOrderByFilter(field: string) {
        if (filters.orderBy == field) {
            return <img src={filters.ascending ? FilterUpIcon : FilterDownIcon} />
        }

        return <></>;
    }

    return (<div className="findings-table">
        <div className="findings-table-heading">
            <div onClick={() => handleSetOrderBy('date')}>
                {renderOrderByFilter('date')}
                <span>Datum</span>
            </div>
            <div onClick={() => handleSetOrderBy('identifier')}>
                {renderOrderByFilter('identifier')}
                <span>Číslo</span>
            </div>
            {/* <span>Fotky</span> */}
            <div>
                {
                    filters.findingTypesIds.length > 0 &&
                    <div className="filter-active-circle"></div>
                }
                <span onClick={(e) => selectMultiselectHandler(e, "finding-type")}>Typ</span>
                {
                    showMultiselect == "finding-type" &&
                    <FindingsItemsFilter
                        selectedIds={filters.findingTypesIds}
                        items={findingTypes}
                        nameExtractor={item => item.identifier}
                        setFilters={(ids) => dispatch(setFindingTypesFilter(ids))}
                        onClickOutside={() => setShowMultiselect("")}
                    />
                }
            </div>
            <div>
                {
                    filters.materialsIds.length > 0 &&
                    <div className="filter-active-circle"></div>
                }
                <span onClick={(e) => selectMultiselectHandler(e, "material")}>Materiál</span>
                {
                    showMultiselect == "material" &&
                    <FindingsItemsFilter
                        selectedIds={filters.materialsIds}
                        items={materials}
                        nameExtractor={item => item.identifier}
                        setFilters={(ids) => dispatch(setMaterialsFilter(ids))}
                        onClickOutside={() => setShowMultiselect("")}
                    />
                }
            </div>
            <div onClick={() => handleSetOrderBy('weight')}>
                {renderOrderByFilter('weight')}
                <span>Hmotnost</span>
            </div>
            <div>
                {
                    filters.ratings.length > 0 &&
                    <div className="filter-active-circle"></div>
                }
                <span onClick={(e) => selectMultiselectHandler(e, "ratings")}>Hodnocení</span>
                {
                    showMultiselect == "ratings" &&
                    <FindingsRatingsFilter
                        selectedRatings={filters.ratings}
                        setSelectedRatings={(ratings) => dispatch(setRatingsFilter(ratings))}
                        onClickOutside={() => setShowMultiselect("")}
                    />
                }
            </div>
            <span>Tisk štítků</span>
        </div>
        <ul className="findings-table-body" onMouseLeave={handlePreviewLeave}>
            {findings.map(finding =>
                <li
                    key={finding.id}
                    onMouseOver={(e) => handlePreview(e, finding)}
                    onClick={(e) => handleSelect(e, finding)}
                    className={(finding == selection?.finding && selection.selected) ? "selected" : ""}
                >
                    <span>{finding.created.split('-').reverse().join('.')}</span>
                    <span>{finding.identifier}</span>
                    {/* <span>{finding.images.length}</span> */}
                    <span>{finding.findingType.identifier}</span>
                    <span>{finding.material.identifier}</span>
                    <span>{finding.weight?.toFixed(0) ?? '-'} g</span>
                    <span>
                        <FindingRating rating={finding.rating} />
                    </span>
                    <span>
                        <FindingsTableRowPrint finding={finding} />
                    </span>
                </li>
            )}
        </ul>

        {
            filtersActive &&
            <div className="findings-table-reset-filters">
                <button className="primary" onClick={() => dispatch(resetFindingFilters())}>Vymazat filtry</button>
            </div>
        }
    </div>);
}