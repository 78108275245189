import React, { useRef } from "react";

interface Props {
    show: boolean
    setShow: (state: boolean) => void,
    children: JSX.Element,
};

export function Modal({ show, setShow, children }: Props) {
    if (!show) {
        return null;
    }

    function handleOnClick(e: React.MouseEvent) {
        if (e.target != e.currentTarget) return;
        setShow(false);
    }

    return (<div className="modal" onClick={handleOnClick}>
        {children}
    </div>);
}