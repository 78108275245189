import { useState } from "react";
import { Modal } from "../Modal";

interface Props {
    show: boolean,
    setShow: (state: boolean) => void,
    handleSelectedPrintArea: (area: number) => void
}

export function PrintAreaModal({ show, setShow, handleSelectedPrintArea }: Props) {
    const areas = [1, 2, 3, 4];
    const [selectedArea, setSelectedArea] = useState(1);

    return (<Modal show={show} setShow={setShow}>
        <div className="print-area-modal">
            <h2>Zvolte si oblast tisku</h2>
            <div className="areas">
                {
                    areas.map(a => <button
                        className={`${a == selectedArea ? 'primary' : ''}`}
                        onClick={() => setSelectedArea(a)}>{a}</button>
                    )
                }
            </div>
            <button className="primary" onClick={() => handleSelectedPrintArea(selectedArea)}>Potvrdit</button>
            <button onClick={() => setShow(false)}>Zrušit</button>
        </div>
    </Modal>);
}