import { API_URL } from "../../config";
import { RouteModel } from "../../models/route.model";

import axios from "axios";
import { buildAuthorizationHeaders } from "./headers";

export async function getAreaRoutes(areaId: string): Promise<RouteModel[]> {
    const response = await axios(`${API_URL}/api/areas/${areaId}/routes`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders(),
        }
    });

    return response?.data?.routes as RouteModel[];
}