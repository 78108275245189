import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Header } from './components/Header';
import { useLoadFindingTypes } from './hooks/findingTypes/load-finding-types.hook';
import { useLoadMaterials } from './hooks/materials/load-materials.hook';
import { Area } from './pages/Area';
import { Areas } from './pages/Areas';
import { Login } from './pages/Login';
import { CreateArea } from './pages/CreateArea';
import { Settings } from './pages/Settings';
import { selectToken } from './state/features/auth';
import { useAppSelector } from './state/hooks';
import { EditArea } from './pages/EditArea';
import { useLoadCurrentUser } from './hooks/users/load-current-user.hook';
import { useLoadLocalities } from './hooks/localities/localities.hook';

function App() {
  const token = useAppSelector(selectToken);

  const loadMaterials = useLoadMaterials();
  const loadFindingTypes = useLoadFindingTypes();
  const loadLocalities = useLoadLocalities();

  const { loadCurrentUser, unloadCurrentUser } = useLoadCurrentUser();

  // on login change
  useEffect(() => {
    if (token) {
      loadMaterials();
      loadFindingTypes();
      loadLocalities();

      loadCurrentUser();
    } else {
      unloadCurrentUser();
    }
  }, [token]);

  if (!token) return (<div className="app">
    <Login />
  </div>)

  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Areas />} />
          <Route path='/areas' element={<Areas />} />
          <Route path='/areas/:id' element={<Area />} />
          <Route path='/create/area' element={<CreateArea />} />
          <Route path='/areas/:id/edit' element={<EditArea />} />
          <Route path='/settings' element={<Settings />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
