import { useForm } from "react-hook-form";
import { login } from "../../services/Api";
import { setToken } from "../../state/features/auth";
import { useAppDispatch } from "../../state/hooks";
import { LoginValidator } from "../../validators/login.validator";

import { toast } from "react-toastify";

export function Login() {
    const dispatch = useAppDispatch();

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });

    async function submit(data: any) {
        try {
            const token = await login(data.email, data.password);
            dispatch(setToken(token));

            toast("Uživatel přihlášen", { type: "success" });
        } catch (_) {
            toast("Nesprávné přihlašovací údaje", { type: "error" });
        }
    }

    return (<div className="page-login">
        <h1>Přihlášení do systému hledaček</h1>

        <form onSubmit={handleSubmit(submit)}>
            <div className="form-control">
                <label>E-mail</label>
                <input type="email" {...register('email', LoginValidator.email)} />
                <span className="form-control-errors">{errors?.email?.message}</span>
            </div>
            <div className="form-control">
                <label>Heslo</label>
                <input type="password" {...register('password', LoginValidator.password)} />
                <span className="form-control-errors">{errors?.password?.message}</span>
            </div>
            <button type="submit">Přihlásit se</button>
        </form>
    </div>);
}