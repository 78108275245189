import { getFindingTypes } from "../../services/Api";
import { setFindingTypes } from "../../state/features/findingType";
import { useAppDispatch } from "../../state/hooks";

export function useLoadFindingTypes() {
    const dispatch = useAppDispatch();

    async function loadFindingTypes() {
        const findingTypes = await getFindingTypes();
        dispatch(setFindingTypes(findingTypes));
    }

    return loadFindingTypes;
}