import React, { useEffect } from "react";
import { EntityModel } from "../../models/entity.model";

interface Props<T> {
    items: T[],
    nameExtractor: (item: T) => string,
    onChange: (item: T) => void,
    value?: T
}

export function ItemsSelect<T extends EntityModel>({ items, nameExtractor, onChange, value }: Props<T>) {

    function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const index = parseInt(e.target.value);

        const item = items[index] ?? undefined;
        onChange(item);
    }

    function getSelectValue(): number {

        for (let i = 0; i < items.length; i++) {
            // TODO: extract comparer into optional prop
            if (items[i].id == value?.id) {
                return i;
            }
        }

        return -1;
    }

    return <select onChange={handleChange} value={getSelectValue()}>
        {
            value == undefined &&
            <option key={"new"} value={-1}>-</option>
        }
        {items.map((item, index) =>
            <option key={item.id} value={index}>{nameExtractor(item)}</option>
        )}
    </select>;
}