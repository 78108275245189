import { useClickOutside } from "../../hooks/click-oustide.hook";
import { EntityModel } from "../../models/entity.model";

interface Props<T> {
    items: T[],
    nameExtractor: (item: T) => string,
    setFilters: (items: string[]) => void,
    selectedIds: string[],
    onClickOutside: () => void
}

export function FindingsItemsFilter<T extends EntityModel>({ items, nameExtractor, setFilters, selectedIds, onClickOutside }: Props<T>) {
    const clickOutsideRef = useClickOutside(onClickOutside);

    function handleSelect(item: T) {
        const index = selectedIds.indexOf(item.id);
        let newSelected = [] as string[];
        if (index >= 0) {
            newSelected = [...selectedIds.filter(i => item.id != i)];
        } else {
            newSelected = [...selectedIds, item.id];
        }

        if (setFilters) {
            setFilters(newSelected);
        }
    }

    return (<ul className="findings-items-filter" ref={clickOutsideRef as any}>
        {
            items.map(item =>
                <li key={item.id} onClick={() => handleSelect(item)}>
                    <div className={selectedIds.includes(item.id) ? "filter-checkbox checked" : "filter-checkbox"}></div>
                    <span>{nameExtractor(item)}</span>
                </li>)
        }
    </ul>);
}