import { extractAccessToken } from "../../helpers/token.helper";

export function buildAuthorizationHeaders() {
    return {
        Authorization: `Bearer ${extractAccessToken()}`
    };
}

export function buildJSONContentTypeHeaders() {
    return {
        "Content-Type": "application/json"
    };
}

export function buildMultipartFormDataContentTypeHeaders() {
    return {
        "Content-Type": "multipart/form-data"
    };
}