import { useNavigate } from "react-router-dom";

import AppIcon from "../../assets/icons/app-icon.svg";

export function Header() {
    const navigate = useNavigate();

    return (<header>
        <div>
            <img src={AppIcon} />
            <span onClick={() => navigate('/areas')}>ToJeToMisto.cz</span>
        </div>
        <div>
            <button onClick={() => navigate('/settings')}>Nastavení</button>
        </div>
    </header>);
}