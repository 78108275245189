import { useEffect, useState } from "react";
import { RouteModel } from "../../models/route.model";
import { getAreaRoutes } from "../../services/Api/routes";
import { Modal } from "../Modal";
import { RouteInput } from "../RouteInput";

interface Props {
    show: boolean,
    setShow: (state: boolean) => void,
    areaId: string
}

export function RouteManagerModal({ show, setShow, areaId }: Props) {

    const [routes, setRoutes] = useState<RouteModel[]>([]);

    async function fetchAreaRoutes() {
        const routesData = await getAreaRoutes(areaId);
        setRoutes(routesData);
    }

    useEffect(() => {
        if (!areaId) return;

        fetchAreaRoutes();
    }, [areaId]);

    return <Modal show={show} setShow={setShow}>
        <div>
            <div>
                <RouteInput />
            </div>
            <ul>
                {
                    routes.map(route => <li key={route.id}>{route.created}</li>)
                }
            </ul>
        </div>
    </Modal>;
}