import { forwardRef } from "react";
import { getPrintTagDate } from "../../helpers/date.helper";
import { FindingModel } from "../../models/finding.model";
import { selectCurrentUser } from "../../state/features/user";
import { useAppSelector } from "../../state/hooks";

interface Props {
    finding: FindingModel,
    ref: any
}

export const SmallPrintTag = forwardRef(({ finding }: Props, ref: any) => {
    const currentUser = useAppSelector(selectCurrentUser);

    function getDate() {
        return getPrintTagDate(finding);
    }

    return (<div className="print small-print-tag" ref={ref}>
        <div className="small-print-tag-header">
            <span>{getDate()}</span>
            <span>{finding.identifier}</span>
        </div>
        <h2>{finding.landRegistry.length > 0 ? finding.landRegistry : "-"}</h2>
        <p>
            <span>Lokalita: {finding.locality?.identifier ?? "-"}</span>,&nbsp;
            <span>Hmotnost: {finding.weight}g</span>
        </p>
        <p>GPS: {finding.latitude.toFixed(7)}, {finding.longitude.toFixed(7)}</p>
        <p>Nálezce: {currentUser?.fullName}</p>
    </div>);
});