export const CreateAreaValidator = {
    name: {
        required: "Název je povinná položka",
        minLength: {
            value: 3,
            message: "Název nesmí být kratší než 3 znaky."
        },
        maxLength: {
            value: 30,
            message: "Název nesmí být delší než 30 znaků."
        }
    },
    description: {
        maxLength: {
            value: 300,
            message: "Popisek nesmí být delší než 300 znaků."
        }
    }
}