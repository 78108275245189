//@ts-ignore
import toGeoJson from "@mapbox/togeojson";

export function RouteInput() {
    async function handleFileChange(e: React.ChangeEvent) {
        const target = e.target as HTMLInputElement;
        if (target == undefined) return;

        const file = target.files?.[0];
        if (!file) return;

        const string = await file.text();

        const parser = new DOMParser();
        const xml = parser.parseFromString(string, "application/xml");

        const geojson = toGeoJson.gpx(xml);

        console.log("GPX FILE", geojson);
    }

    return <div>
        <input onChange={handleFileChange} type="file" name="image" />
    </div>;
}