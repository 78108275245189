import axios from "axios";
import { API_URL } from "../../config";
import { ImageModel } from "../../models/image.model";
import { buildAuthorizationHeaders, buildMultipartFormDataContentTypeHeaders } from "./headers";

export async function uploadAreaImage(file: File | Blob): Promise<ImageModel> {
    const data = new FormData();
    data.append("image", file);

    const response = await axios(`${API_URL}/api/images/area/upload`, {
        method: "POST",
        data,
        headers: {
            ...buildAuthorizationHeaders(),
            ...buildMultipartFormDataContentTypeHeaders()
        }
    });

    return response.data?.image as ImageModel
}

export async function uploadFindingImage(file: File | Blob): Promise<ImageModel> {
    const data = new FormData();
    data.append("image", file);

    const response = await axios(`${API_URL}/api/images/finding/upload`, {
        method: "POST",
        data,
        headers: {
            ...buildAuthorizationHeaders(),
            ...buildMultipartFormDataContentTypeHeaders()
        }
    });

    return response.data?.image as ImageModel
}

export async function deleteFindingImage(id: string) {
    await axios(`${API_URL}/api/images/finding/${id}`, {
        method: "DELETE",
        headers: {
            ...buildAuthorizationHeaders(),
        }
    });
}