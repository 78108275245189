import StarIcon from "../../assets/icons/star.svg";
import StarFilledIcon from "../../assets/icons/star-filled.svg";

interface Props {
    value: number,
    onChange: (value: number) => void
}

export function FindingRatingInput({ value, onChange }: Props) {
    const ratingListItems = [];

    function selectRating(rating: number) {
        if (value == rating) {
            onChange(0);
        } else {
            onChange(rating);
        }
    }

    for (let i = 1; i <= 3; i++) {
        if (i <= value) {
            ratingListItems.push(<li key={i} onClick={() => selectRating(i)}><img src={StarFilledIcon} /></li>);
        } else {
            ratingListItems.push(<li key={i} onClick={() => selectRating(i)}><img src={StarIcon} /></li>);
        }
    }

    return <ul className="finding-rating finding-rating-input">{ratingListItems}</ul>;
}