import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { FindingModel } from "../../models/finding.model";
import { SmallPrintTag } from "../SmallPrintTag";

import PrintIcon from "../../assets/icons/print.svg";
import DocumentIcon from "../../assets/icons/document.svg";
import { PrintAreaModal } from "../PrintAreaModal";
import { LargePrintTag } from "../LargePrintTag";
import { LargePrintTagSettingsModel } from "../../models/print/large-print-tag.model";
import { LargePrintTagPreviewModal } from "../LargePrintTagPreviewModal";
import { getFinding } from "../../services/Api";

interface Props {
    finding: FindingModel
}

// TODO: temp component, needs rework
export function FindingsTableRowPrint({ finding: originalFinding }: Props) {
    const [showPrintAreaModal, setShowPrintAreaModal] = useState(false);

    const [finding, setFinding] = useState<FindingModel | undefined>(undefined);

    const smallPrintTag = useRef(null);

    const handlePrintFindingSmall = useReactToPrint({
        content: () => smallPrintTag.current,
        pageStyle: "@page { size: 3.81cm 2.51cm; }",
    });

    const largePrintTag = useRef(null);
    const [largePrintTagArea, setLargePrintTagArea] = useState(-1);
    const [showPrintPreviewModal, setShowPrintPreviewModal] = useState(false);

    // TODO: move to separate component?
    const [largePrintTagSettings, setLargePrintTagSettings] = useState<LargePrintTagSettingsModel>({
        imageVariant: "double",
        images: [
            {
                scale: 1, x: 0, y: 0
            },
            {
                scale: 1, x: 0, y: 0
            },
            {
                scale: 1, x: 0, y: 0
            }
        ],
        mapPosition: {
            latitude: originalFinding.latitude, longitude: originalFinding.longitude, zoom: 12
        }
    });

    const handlePrintFindingLarge = useReactToPrint({
        content: () => largePrintTag.current,
        pageStyle: "@page { size: A4; }",
        onBeforeGetContent: () => {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 1000);
            });
        },
        onAfterPrint() {
            setLargePrintTagArea(-1);
        },
    });

    function printFindingLarge(area: number) {
        setLargePrintTagArea(area);
    }

    useEffect(() => {
        if (largePrintTagArea < 0) return;

        handlePrintFindingLarge();
        setShowPrintAreaModal(false);

    }, [largePrintTagArea]);

    useEffect(() => {
        // get finding data from api
        getFinding(originalFinding.id).then(value => {
            setFinding(value);
        });
    }, []);

    if (finding == undefined) {
        return <></>;
    }

    return <>
        <button type="button" className="icon" onClick={e => { e.preventDefault(); handlePrintFindingSmall(); }} title="Tisknout malý štítek.">
            <img src={PrintIcon} />
        </button>

        <button style={{ marginLeft: 6 }} type="button" className="icon" onClick={() => setShowPrintPreviewModal(true)} title="Tisknout velký štítek.">
            <img src={DocumentIcon} />
        </button>

        <PrintAreaModal
            show={showPrintAreaModal}
            setShow={setShowPrintAreaModal}
            handleSelectedPrintArea={printFindingLarge}
        />

        <SmallPrintTag finding={finding} ref={smallPrintTag} />

        <LargePrintTagPreviewModal show={showPrintPreviewModal} setShow={setShowPrintPreviewModal} printData={{ finding, settings: largePrintTagSettings }} setPrintSettings={setLargePrintTagSettings} handlePrint={() => { setShowPrintPreviewModal(false); setShowPrintAreaModal(true); }}></LargePrintTagPreviewModal>

        {
            largePrintTagArea >= 0 &&
            <div style={{ position: "absolute", zIndex: 9999, left: 0, top: 0 }}>
                <LargePrintTag print={true} printData={{ finding, settings: largePrintTagSettings }} area={largePrintTagArea} ref={largePrintTag} />
            </div>
        }
    </>
}