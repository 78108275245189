import axios from "axios";
import { API_URL } from "../../config";
import { AreaGeojsonModel } from "../../models/geojson/area-geojson.model";
import { AreaModel } from "../../models/area.model";
import { AreasGeojsonModel } from "../../models/geojson/areas-geojson.model";
import { FindingTypeModel } from "../../models/finding-type.model";
import { MaterialModel } from "../../models/material.model";
import { buildAuthorizationHeaders, buildJSONContentTypeHeaders } from "./headers";

import { toast } from "react-toastify";

export * from "./auth";
export * from "./findings";
export * from "./storage";


// areas

export async function getAreas(): Promise<AreaModel[]> {
    const res = await axios(`${API_URL}/api/areas`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return res.data?.areas as AreaModel[] ?? undefined;
}

export async function getAreasGeojson(): Promise<AreasGeojsonModel> {
    const res = await axios(`${API_URL}/api/areas/geojson`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return res.data as AreasGeojsonModel ?? undefined;
}

export async function getArea(id: string): Promise<AreaModel> {
    const res = await axios(`${API_URL}/api/areas/${id}`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return res.data?.area as AreaModel ?? undefined;
}

export async function getAreaGeojson(id: string): Promise<AreaGeojsonModel> {
    const res = await axios(`${API_URL}/api/areas/${id}/geojson`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return res.data as AreaGeojsonModel ?? undefined;
}

export async function deleteArea(id: string) {
    await axios(`${API_URL}/api/areas/${id}`, {
        method: "DELETE",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });
}

export async function createArea(name: string, description: string, coordinates: number[][][], imageIds: string[]): Promise<AreaModel> {
    try {
        const res = await axios(`${API_URL}/api/areas`, {
            data: {
                name,
                description,
                coordinates,
                imageIds
            },
            method: "POST",
            headers: {
                ...buildAuthorizationHeaders(),
                ...buildJSONContentTypeHeaders()
            }
        });

        return res.data?.area as AreaModel ?? undefined;
    } catch (e: any) {
        toast(e.message, { type: "error" });
        throw new Error("");
    }
}

export async function putArea(id: string, name: string, description: string, coordinates: number[][][], imageIds: string[]) {
    const res = await axios(`${API_URL}/api/areas/${id}`, {
        data: {
            name,
            description,
            coordinates,
            imageIds
        },
        method: "PUT",
        headers: {
            ...buildAuthorizationHeaders(),
            ...buildJSONContentTypeHeaders()
        }
    });

    return res.data?.area as AreaModel ?? undefined;
}


// materials

export async function getMaterials(): Promise<MaterialModel[]> {
    const res = await axios(`${API_URL}/api/materials`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return res.data?.materials as MaterialModel[] ?? undefined;
}

export async function createMaterial(identifier: string, color: number): Promise<MaterialModel> {
    const res = await axios(`${API_URL}/api/materials`, {
        data: {
            identifier,
            color
        },
        method: "POST",
        headers: {
            ...buildAuthorizationHeaders(),
            ...buildJSONContentTypeHeaders()
        }
    });

    return res?.data?.material as MaterialModel ?? undefined;
}

export async function deleteMaterial(id: string) {
    await axios(`${API_URL}/api/materials/${id}`, {
        method: "DELETE",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });
}

// finding types

export async function getFindingTypes(): Promise<FindingTypeModel[]> {
    const res = await axios(`${API_URL}/api/findingTypes`, {
        method: "GET",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });

    return res.data?.findingTypes as FindingTypeModel[] ?? undefined;
}

export async function createFindingType(identifier: string, icon: string, iconSize: number): Promise<FindingTypeModel> {
    const res = await axios(`${API_URL}/api/findingTypes`, {
        data: {
            identifier,
            icon,
            iconSize
        },
        method: "POST",
        headers: {
            ...buildAuthorizationHeaders(),
            ...buildJSONContentTypeHeaders()
        }
    });

    return res?.data?.FindingType as FindingTypeModel ?? undefined;
}

export async function deleteFindingType(id: string) {
    await axios(`${API_URL}/api/findingTypes/${id}`, {
        method: "DELETE",
        headers: {
            ...buildAuthorizationHeaders()
        }
    });
}
