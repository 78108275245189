import { useForm } from "react-hook-form"
import { toast } from "react-toastify";
import { getDefaultDetector, setDefaultDetector } from "../../helpers/defaults.helper";

export function DetectorSettings() {
    const { handleSubmit, register } = useForm();

    function onSubmit({ detector }: any) {
        setDefaultDetector(detector);

        toast("Výchozí detektor uložen.", { type: "success" });
    }


    return <form onSubmit={handleSubmit(onSubmit)} className="detector-settings">
        <div className="form-control">
            <label>Výchozí detektor</label>
            <input
                type="text"
                // defaultValue={getDefaultDetector()}
                // onChange={e => setDefaultDetector(e.target.value)}
                {...register("detector", { value: getDefaultDetector() })}
            />
        </div>
        <button className="primary">Uložit detektor</button>
    </form>
}