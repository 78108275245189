import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ImagesEditor } from "../../components/ImagesEditor";
import { MapNewArea } from "../../components/MapNewArea";
import { AreaModel } from "../../models/area.model";
import { ImageModel } from "../../models/image.model";
import { getArea, getAreaGeojson, putArea, uploadAreaImage } from "../../services/Api";

export function EditArea() {
    const navigate = useNavigate();

    const { id } = useParams();

    const dataExtractor = useRef<() => any>(() => { });

    const [area, setArea] = useState<AreaModel>();
    const [areaGeojson, setAreaGeojson] = useState(undefined as any);

    async function fetchData() {
        if (id == undefined) return;

        const res = await getAreaGeojson(id);
        setAreaGeojson({ type: "FeatureCollection", features: [res.areaGeojson] });

        const data = await getArea(id);
        setArea(data);
    }

    useEffect(() => {
        fetchData();
    }, []);


    const { handleSubmit, register, control } = useForm();

    async function submit(formData: any) {
        if (area == undefined) return;

        const data = dataExtractor.current();
        const coordinates = data.features[0]?.geometry?.coordinates;

        await putArea(area.id, formData.name, formData.description, coordinates ?? [], formData.images.map((i: ImageModel) => i.id));
        navigate(`/areas/${id}`);
    }

    if (area == undefined) {
        return (<span>Loading...</span>);
    }

    return (<div className="page-create-area">
        <section className="page-create-area-sidebar" >
            <button
                style={{ marginBottom: 24 }}
                className="page-createa-area-back"
                onClick={() => navigate(`/areas/${id}`)}
            >
                zpět na detail oblasti
            </button>

            <h2>Upravit oblast {area.name}</h2>
            <form onSubmit={handleSubmit(submit)}>
                <div className="form-control">
                    <label>Název</label>
                    <input type="text" {...register("name", { value: area.name })} />
                </div>

                <div className="form-control">
                    <label>Popis</label>
                    <textarea {...register("description", { value: area.description })}></textarea>
                </div>

                <div className="form-control">
                    <label>Fotky</label>

                    <Controller
                        control={control}
                        name={"images"}
                        defaultValue={area.images}
                        render={({ field: { onChange, value } }) =>
                            <ImagesEditor
                                images={value}
                                setImages={onChange}
                                uploadFunction={uploadAreaImage}
                                deleteImage={(image) => {
                                    onChange(value.filter((i: ImageModel) => i.id != image.id))
                                }}
                            />
                        }
                    />
                </div>

                <button type="submit">Uložit</button>
            </form>
        </section>
        <section className="page-create-area-map">
            {
                areaGeojson &&
                <MapNewArea
                    dataExtractor={dataExtractor}
                    initialGeojson={areaGeojson}
                />
            }
        </section>
    </div>);
}