import axios from "axios";
import { API_URL } from "../../config";
import { buildAuthorizationHeaders, buildJSONContentTypeHeaders } from "./headers";

export async function login(email: string, password: string): Promise<string> {
    const res = await axios(`${API_URL}/api/auth/login`, {
        method: "POST",
        data: {
            email,
            password
        },
        headers: {
            ...buildJSONContentTypeHeaders()
        }
    });

    return res?.data?.token ?? "";
}