import { useRef } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { ImageModel } from "../../models/image.model";

interface Props {
    image: ImageModel,
    index: number
    onDelete: () => void,
    onMove: (from: number, to: number) => void,
    onData?: () => void
}

export function ImageEntry({ image, index, onDelete, onMove, onData }: Props) {
    const ref = useRef<HTMLElement>(null);

    const [{ handlerId }, drop] = useDrop<{ index: number, image: ImageModel }, void, { handlerId: any }>({
        accept: "image",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover: (item, monitor) => {
            if (item.index == index) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            if (!hoverBoundingRect) {
                return;
            }

            const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.right;

            if (item.index < index && hoverClientX < hoverMiddleX) {
                return
            }

            if (item.index > index && hoverClientX > hoverMiddleX) {
                return
            }

            onMove(item.index, index);
            item.index = index;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: "image",
        item: () => {
            return { index, image };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return <div ref={ref as any} className={isDragging ? "dragging" : ""} data-handler-id={handlerId}>
        <button type="button" onClick={onDelete} className="primary delete">X</button>
        {
            onData != undefined &&
            <button type="button" onClick={onData} className="secondary data">GPS</button>
        }
        <img src={image.url} />
    </div>;
}