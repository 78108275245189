import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { MapAreas } from "../../components/MapAreas";
import { AreaModel } from "../../models/area.model";
import { deleteArea, getArea, getAreaGeojson } from "../../services/Api";

import { FindingsTable } from "../../components/FindingsTable";
import { Modal } from "../../components/Modal";

import { downloadBlob } from "../../helpers/file.helper";
import { CreateFindings } from "../../components/CreateFindings";
import { useAreaFindings } from "../../hooks/findings/area-findings.hook";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { resetFindingEditing, selectFindingEditing } from "../../state/features/finding-selection";
import { EditFinding } from "../../components/EditFinding";

import DeleteIcon from "../../assets/icons/delete.svg";
import EditIcon from "../../assets/icons/edit.svg";

// @ts-ignore
import togpx from "togpx";
import Fancybox from "../../components/Fancybox/component";
import { DeleteModal } from "../../components/DeleteModal";
import { RouteManagerModal } from "../../components/RouteManagerModal";
import { sanitizeAreaName } from "../../helpers/area.helper";

export function Area() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showRouteManagerModal, setShowRouteManagerModal] = useState(false);

    const { id } = useParams();
    const [area, setArea] = useState<AreaModel>();

    const [findingsGeojson, setFindingsGeojson] = useState(undefined as any);
    const [areaGeojson, setAreaGeojson] = useState(undefined as any);

    const [createFindingModal, setCreateFindingModal] = useState(false);
    const editingFinding = useAppSelector(selectFindingEditing);

    const { findings, fetchFindings } = useAreaFindings(area?.id);

    async function fetchData() {
        if (id == undefined) return;

        const data = await getArea(id);
        setArea(data);

        const res = await getAreaGeojson(id);
        setFindingsGeojson(res.findingsGeojson);
        setAreaGeojson({ type: "FeatureCollection", features: [res.areaGeojson] });
    }

    async function findingCreated() {
        setCreateFindingModal(false);
        await fetchData(); // TODO: remove, because of geojson
        await fetchFindings();
    }

    async function handleDeleteArea() {
        if (id == undefined) return;

        await deleteArea(id);
        navigate("/areas");
    }

    async function confirmEdit() {
        dispatch(resetFindingEditing());

        await fetchData(); // TODO: remove, because of geojson
        await fetchFindings();
    }

    useEffect(() => {
        fetchData();
    }, [id]);

    function exportFindingsGPX() {
        const gpx = togpx(findingsGeojson);
        downloadBlob(`${area?.name}_${new Date().toISOString().split('T')[0]}.gpx`, new Blob([gpx]));
    }

    useEffect(() => {
        // TODO: called twice, why?
    }, []);

    if (id == undefined) {
        return <div>Area not found...</div>
    }

    if (area == undefined) {
        return <div>loading...</div>
    }

    return <div className="page-area">
        <section className="page-area-info">
            <button
                className="page-area-info-back"
                onClick={() => navigate('/areas')}
            >
                zpět na přehled oblastí
            </button>
            <div className="page-area-info-text">
                <h1>Oblast {sanitizeAreaName(area.name)}</h1>
                <p >
                    {area.description}
                </p>
            </div>
            <div className="page-area-info-photos">
                <h2>Fotky ({area.images.length})</h2>
                <Fancybox>
                    <ul>
                        {area.images.map(image =>
                            <li key={image.id}>
                                <a
                                    className="fancybox-image"
                                    data-fancybox="gallery-area"
                                    data-src={image.url}
                                >
                                    <img
                                        className='swiper-image'
                                        src={image.url}
                                    />
                                </a>
                            </li>)}
                    </ul>
                </Fancybox>
            </div>

            <div className="page-area-info-findings">
                <h2>Nálezy ({findings.length})</h2>
                <FindingsTable findings={findings} />
            </div>

            <div className="page-area-info-buttons">
                <button className="primary" onClick={() => setCreateFindingModal(true)}>Přidat nálezy</button>
                <button className="secondary" onClick={exportFindingsGPX}>Exportovat nálezy</button>
                {/* DEV ONLY */}
                {/* <button className="secondary" onClick={() => setShowRouteManagerModal(true)}>Nahrát trasu</button> */}
            </div>

            <div className="page-area-info-actions">
                <button className="icon" onClick={() => navigate(`/areas/${id}/edit`)}>
                    <img src={EditIcon} />
                </button>
                <button className="icon" onClick={() => setShowDeleteModal(true)}>
                    <img src={DeleteIcon} />
                </button>
            </div>
        </section>
        <section className="page-area-map">
            <MapAreas
                areasGeojson={areaGeojson}
                findingsGeojson={findingsGeojson}
                showFindingEdit={true}
                showFindingFilters={false}
            />
        </section>

        <Modal show={createFindingModal} setShow={setCreateFindingModal}>
            <CreateFindings areaId={id} onCreated={findingCreated} />
        </Modal>

        <DeleteModal
            show={showDeleteModal}
            setShow={setShowDeleteModal}
            alertText={`Opravdu si přejete odstranit oblast ${area.name}?`}
            onDelete={handleDeleteArea}
        />

        <RouteManagerModal
            show={showRouteManagerModal}
            setShow={setShowRouteManagerModal}
            areaId={id}
        />

        {
            editingFinding &&
            <Modal show={editingFinding != undefined} setShow={() => dispatch(resetFindingEditing())}>
                <EditFinding
                    finding={editingFinding}
                    onEdit={confirmEdit}
                />
            </Modal>
        }
    </div >
}